export function isfFullyInViewport(element) {
    let rect = element.getBoundingClientRect();
    let html = document.documentElement;
    return (
        rect.top >= 0 &&
        rect.left >= 0 &&
        rect.bottom <= (window.innerHeight || html.clientHeight) &&
        rect.right <= (window.innerWidth || html.clientWidth)
    );
}

export function isPartlyInViewportY(element) {
    let rect = element.getBoundingClientRect();
    let html = document.documentElement;
    return (
        rect.top < (window.innerHeight || html.clientHeight) &&
        rect.bottom >= 0
    );
}

export function isPartlyInViewportX(element) {
    let rect = element.getBoundingClientRect();
    let html = document.documentElement;
    return (
        rect.left < (window.innerWidth || html.clientWidth) &&
        rect.right >= 0
    );
}

export function isInViewport(element) {
    let rect = element.getBoundingClientRect();
    let html = document.documentElement;
    return (
        rect.top >= 0 &&
        rect.left >= 0 &&
        rect.right <= (window.innerWidth || html.clientWidth)
    );
}

export function offset(el) {
    let rect = el.getBoundingClientRect(),
        scrollLeft = window.pageXOffset || document.documentElement.scrollLeft,
        scrollTop = window.pageYOffset || document.documentElement.scrollTop;
    return { top: rect.top + scrollTop, left: rect.left + scrollLeft }
}

export function getRandomInt(max) {
    return Math.floor(Math.random() * Math.floor(max));
}

export function getRandomIntMaxExclusive(min, max) {
    min = Math.ceil(min);
    max = Math.floor(max);
    return Math.floor(Math.random() * (max - min)) + min;
}

export function getRandomArbitrary(min, max) {
    return Math.random() * (max - min) + min;
}

export function getRandomIntInclusive(min, max) {
    min = Math.ceil(min);
    max = Math.floor(max);
    return Math.floor(Math.random() * (max - min +1)) + min;
}