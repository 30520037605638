<template>
    <div class="lang-switcher">
        <select @change="onChangeHandler" v-model="$i18n.locale">
            <option :value="locale.code" v-for="locale in locales" :key="locale.code">
                {{locale.name}}
            </option>
        </select>
    </div>
</template>

<script>
import { getSupportedLocales } from "@/utils/supported-locales"

export default {
    data() {
        return {
            locales : getSupportedLocales()
        }
    },
    methods: {
        onChangeHandler() {
            this.$router.push({
                params: {locale : this.$i18n.locale}
            })
        }
    }
}
</script>

<style lang="scss">
    .lang-switcher {
        position: fixed;
        z-index: 999;
        top: 15px;
        right: 25px;

        select {
            // appearance: none;
            background: none;
            border-radius: 0;
            border: 1px solid $color-sorrel-brown;
            font-family: "Druk", Helvetica, Arial, sans-serif;
            -webkit-font-smoothing: antialiased;
            -moz-osx-font-smoothing: grayscale;
            font-weight: 500;
            color: $color-white;
            padding: 0px 5px;
            background-color: $color-sorrel-brown;

            border: none;
            -webkit-touch-callout: none; /* iOS Safari */
            -webkit-user-select: none; /* Safari */
            -khtml-user-select: none; /* Konqueror HTML */
            -moz-user-select: none; /* Old versions of Firefox */
            -ms-user-select: none; /* Internet Explorer/Edge */
            user-select: none;
        }
    }
</style>