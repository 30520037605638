<template>
    <div class="scores">
        <div class="points-container">
            <img src="../../assets/bottle-front-big.png" alt />
            <div class="points" v-bind:class="{ 'long-digit' : points.toString().length >= 4 }">
                <span>{{ points }}</span>
                <div class="multiplier desktop">
                    <sub>x</sub>
                    <span>{{ multi }}</span>
                </div>
            </div>
            <div class="multiplier mobile">
                <sub>x</sub>
                <span>{{ multi }}</span>
            </div>
        </div>
    </div>
</template>


<script>
export default {
    name: "GameScore",
    props: {
        points: Number,
        multi: Number,
    }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>
.scores {
    position: absolute;
    width: 100%;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);

    .mobile {
        display: none;
    }
}

.points-container {
    img {
        max-width: 130px;
        display: inline-block;
        vertical-align: bottom;
    }

    .points {
        position: relative;
        display: inline-block;
        vertical-align: bottom;
        margin-left: 15px;
        font-size: 200px;
        line-height: 170px;
        color: $color-sorrel-brown;

        transition: all 150ms ease;

        &.long-digit {
            font-size: 140px;
            line-height: 120px;
        }
    }

    .multiplier {
        position: absolute;
        top: -30px;
        right: -75px;
        font-size: 60px;
        line-height: normal;
        color: $color-sorrel-brown;

        sub {
            font-size: 40px;
        }

        &::after {
            content: "";
            display: inline-block;
            margin-left: 1px;
            width: 34px;
            height: 48px;
            background-image: url("../../assets/glove.png");
            background-size: contain;
            background-repeat: no-repeat;
        }
    }
}

// Ipad pro vertical
@media screen and (max-width: 1024px) and (orientation: portrait) {
    .scores {
        top: 0;
        left: 0;
        right: 0;
        transform: none;
        text-align: left;
        padding: 0 50px;

        .mobile {
            display: block;
        }

        .desktop {
            display: none;
        }
    }

    .points-container {
        position: relative;

        img {
            width: 35px;
        }

        .points {
            font-size: 45px;
            line-height: 40px;
            margin-left: 5px;
        }

        .multiplier {
            font-size: 45px;
            line-height: 40px;
            top: 18px;
            right: 0;

            sub {
                font-size: 30px;
            }

            &::after {
                content: "";
                display: inline-block;
                vertical-align: top;
                margin-left: 1px;
                width: 28px;
                height: 40px;
                background-image: url("../../assets/glove.png");
                background-size: contain;
                background-repeat: no-repeat;
            }
        }
    }

}


@include max-width(s) {
    .scores {
        top: 0;
        transform: none;
        text-align: left;
        padding: 0 15px;

        .mobile {
            display: block;
        }

        .desktop {
            display: none;
        }
    }

    .points-container {
        img {
            width: 28px;
        }

        .points {
            font-size: 45px;
            line-height: 40px;
            margin-left: 5px;

            &.long-digit {
                font-size: 30px;
                line-height: 25px;
            }
        }

        .multiplier {
            font-size: 45px;
            line-height: 40px;
            top: 0;

            sub {
                font-size: 30px;
            }

            &::after {
                content: "";
                display: inline-block;
                vertical-align: bottom;
                margin-left: 1px;
                width: 30px;
                height: 44px;
                background-image: url("../../assets/glove.png");
                background-size: contain;
                background-repeat: no-repeat;
            }
        }
    }
}

@include max-width(xxs) {
    .points-container {

        .points {
            font-size: 35px;
            line-height: 32px;
        }

        .multiplier {
            font-size: 35px;
            line-height: 32px;
        }
    }
}
</style>
