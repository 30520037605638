<template>
    <div class="final-score-banner">
        <div>
            <p class="score">{{ score }} {{$t('game.points')}}</p>
        </div>
        <div>
            <div class="button-replay">
                <a v-on:click.prevent="$emit('restart')">
                    {{ playAgainCTA }}
                </a>
            </div>
        </div>
    </div>
</template>

<script>

export default {
    name: "FinalScore",
    props: {
        score: Number,
        playAgainCTA: String
    },
    methods: {
        clickHandler() {
            // this.$emit('restart')
        }
    }
};
</script>

<style lang="scss">

.final-score-banner {
    position: absolute;
    top: 50px;
    left: 0;
    right: 0;
    z-index: 2;
    display: flex;
    justify-content: space-between;
    padding: 0 80px;

    .score {
        font-size: 34px;
        line-height: 44px;
        color: $color-guardsman-red;
        text-transform: uppercase;
    }
}

.button-replay {
    position: relative;
    display: inline-block;

    font-size: 34px;
    line-height: 44px;
    letter-spacing: -0.04em;
    text-transform: uppercase;
    cursor: pointer;

    a {
        color: $color-sorrel-brown;
        text-decoration: none;

        &:visited {
            color: $color-sorrel-brown;
        }
    }

    &::after {
        content: '';
        position: absolute;
        left: 0;
        bottom: 0;
        width: 100%;
        height: 2px;
        background-color: $color-sorrel-brown;
    }
}

@media screen and (max-height: 800px) and (min-width: 768px) {
    .final-score-banner {
        top: 20px;
    }
}

@media screen and (max-width: 1280px) {
    .final-score-banner {
        padding: 0 40px;

        .score {
            font-size: 30px;
            line-height: 40px;
        }

        .button-replay {
            font-size: 30px;
            line-height: 40px;
        }
    }
}

@include max-width(m) {
    .final-score-banner {
        padding: 0 40px;

    }
}

@include max-width(s) {
    .final-score-banner {
        position: fixed;
        top: 20px;
        padding: 0 25px;

        .score {
            font-size: 18px;
            line-height: 24px;
        }

        .button-replay {
            font-size: 18px;
            line-height: 24px;
        }
    }
}
</style>
