<template>
    <div class="header" v-bind:class="[{'game-on' : gameOn}, {'tuto-ended' : tutoEnded}]">
        <img class="logo-scandal" src="../assets/logo-scandal.png" alt="">
        <img class="logo-jpg" src="../assets/logo-jpg.png" alt="">
    </div>
</template>

<script>
export default {
    name: "Header",
    props: {
        gameOn: Boolean,
        tutoEnded: Boolean
    }
};
</script>

<style lang="scss">

.header {

    .logo-scandal {
        max-width: 330px;
        margin: 0 auto 15px auto;
        transition: all 450ms $easeOutCubic 1000ms;
    }

    .logo-jpg {
        max-width: 120px;
        margin: auto;
        transition: all 450ms $easeOutCubic 1000ms;
    }
}

@media screen and (max-height: 800px) and (min-width: 768px) {
    .header .logo-scandal {
        max-width: 280px;
    }
}

@media screen and (max-height: 620px) and (min-width: 768px) {
    .header .logo-scandal {
        max-width: 200px;
    }

    .header .logo-jpg {
        max-width: 100px;
    }
}

@include max-width(s) {
    .header {

        &.game-on {
            .logo-scandal {
                transform: scale(0.6);
            }
        }

        @keyframes slideUp {
            0% {transform: translateY(150px);}
            100% {transform: translateY(0);}
        }

        &.tuto-ended .logo-jpg {
            position: fixed;
            bottom: 20px;
            left: 0;
            right: 0;
            animation: slideUp 800ms $easeOutCubic;
        }



        .logo-scandal {
            max-width: 210px;
            margin: 0 auto 5px auto;
        }

        .logo-jpg {
            max-width: 100px;
        }
    }
}

@include max-width(xxs) {
    .header {

        .logo-scandal {
            max-width: 150px;
        }
    }
}

</style>
