<template>
  <div class="tuto" >
    <div class="point-container">
        <gamePoints ref="pointsTuto"
          v-for="(item,index) in pointsTuto" 
          :key="index"
          v-bind:idPoint="index"
          v-bind:datas="item"
        ></gamePoints>
    </div>
    <div class="text-tuto">
      <div v-if="$route.name === 'impo' && $i18n.locale === 'de'" class="text">
        <h3>{{$t('tuto.steps['+step+'].title_impo')}}</h3>
        <p>{{$t('tuto.steps['+step+'].text_impo')}}</p>
      </div>
      <div v-else class="text">
        <h3>{{$t('tuto.steps['+step+'].title')}}</h3>
        <p>{{$t('tuto.steps['+step+'].text')}}</p>
      </div>
      <div class="button-container">
        <div class="next">
          <p v-on:click.prevent = next();>{{$t('tuto.next')}}</p>
        </div>
        <div class="skip">
         
          <p v-on:click.prevent = skip();> {{$t('tuto.skip')}}</p>
        </div>

      </div>
    </div>
  </div>
</template>


<script>
import gamePoints from './GamePoints.vue'
export default {
  name: 'gameTuto',
  components: {
    gamePoints
  },
  data:()=>{
    return{
      step:0,
      pointsTuto:[
        {
          number:"1",
          position:[0.5,0.70],
          show:true
        }
      ]
    }
  },
  methods: {
    skip(){
      this.$emit('endTuto')
    },
    next(){
      if(this.step <1){
        this.step++;
        this.$refs.pointsTuto[0].setSelectable(true)
      }else{
        this.$emit('endTuto')
      }
    }
  },
}
</script>


<style scoped lang="scss">
.tuto{
  width: 100%;
  height: 100%;
  background: rgba(18,28,37,0.6);
  z-index: 750;
  position: absolute;
  display: flex;
  flex-direction: column;
  top:0;
}
.point-container{
  pointer-events: none;
  position: relative;
  flex:  2 1 auto;
  margin-top: 100px;
}
.text-tuto{
  max-width: 500px;
  width: 90%;
  padding: 0 20px;
  margin: 0px auto 60px auto;
  flex:auto;
      display: flex;
    flex-direction: column;
  .text{
    margin-bottom: 30px;
    h3{
      font-family: "Druk", Helvetica, Arial, sans-serif;
      font-style: normal;
      font-weight: 500;
      font-size: 24px;
      line-height: 22px;
      align-items: center;
      text-align: center;
      text-transform: uppercase;
      color: #C5000D;
      margin-bottom: 16px;
    }
    p{
      font-family: Courier;
      font-style: normal;
      font-weight: normal;
      font-size: 15px;
      line-height: 15px;
      align-items: center;
      text-align: center;
      letter-spacing: -0.04em;
      text-transform: uppercase;
          white-space: pre-line;

      color: #C9B68F;
    }
  }
  .button-container{
    flex: auto;
    display: flex;
        flex-direction: column;
    .skip{
      flex: auto;
      text-align: center;
      display: flex;
      align-items: flex-end;
      justify-content: center;
          padding-bottom: 10px;
    }
    .next{
      flex: auto;
      text-align: center;
      p{
        text-decoration-line: underline;
      }
      
    }
    p{
      font-family: "Druk", Helvetica, Arial, sans-serif;
      font-style: normal;
      font-weight: 500;
      font-size: 20px;
      line-height: 23px;
      letter-spacing: -0.04em;
      text-transform: uppercase;
      color: #C9B68F;
      cursor: pointer;
      display:inline-block;
      transition: color 1s ease;
      &:hover{
         color: #ffe9bd;
      }
    }
  }
}
@media screen and  (max-width: 768px) {
  .tuto{
    padding-top: 30%;
  }
  .point-container{
    margin-top:0;
  }
  .text-tuto{
      margin: 0px auto 20px auto;
      width: 100%;
     .text{
       margin-bottom: 22px;
       h3{
        font-size: 24px;
       }
       p{
        font-size: 15px;
       }
     }
     .button-container p{
        font-size: 20px;
     }
  }
}
</style>
