<template>

  <div class="onomatope" v-bind:class="[{actif:show}]">
      <p class="text" :style="styleText">{{text}}</p>
      <div class="img-ono" :style="styleImage"><img :src="publicPath+'game/image/ono-'+onoImg+'.svg'" alt=""></div>
  </div>
</template>


<script>
export default {
    name: 'Onomatope',
    props: {
      text: String,
      size: Number,
      duration:Number,
      color: String
    },
    data:()=>{
        return{
            styleText:{
                fontSize:"",
                color:""
            },
            styleImage:{
                width:"",
            },
            show:false,
            onoImg :1,
            publicPath: process.env.BASE_URL
        }
    },
    methods: {
        setStyle(){
            this.styleText.fontSize = this.size+'px';
            this.styleText.color = this.color;
            this.styleText.animationDuration = this.duration+'s';
            this.styleImage.width = (this.size*2)+'px';
            this.styleImage.animationDuration = (this.duration*0.8)+'s';
            this.styleImage.animationDelay = (this.duration*0.2)+'s';
        }
    },
    watch: {
        text: function(t){
            this.show=false;
            let self = this;
            this.onoImg = getRandomInt(1,3);
            setTimeout(function(){
                if(t!=""){
                    self.show = true;
                }
            },20)
        },
        color:function(){
            this.setStyle()
        },
        size:function(){
            this.setStyle()
        }
    },  
    mounted:function(){
        this.setStyle()
        if(this.text!=""){
            this.show = true;
        }
        console.log( process.env.BASE_URL)
    },
}
function getRandomInt(min, max) {
  min = Math.ceil(min);
  max = Math.floor(max);
  return Math.floor(Math.random() * (max - min)) + min;
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
    .text{
        position: absolute;
        top : 50%;
        left: 50%;
        transform:translate(-50%,-50%);
        z-index: 10;
        opacity: 0;
        animation-duration: 1s;
        animation-fill-mode: both;
        animation-timing-function: ease;
         white-space: pre-line;
         animation-delay: 0.1s;
    }
    .img-ono{
        position: absolute;
        top : 50%;
        left: 50%;
        transform:translate(-50%,-50%);
        z-index: 5;
        opacity : 0;
        color:rgba(0,0,0,0);
        animation-duration: 0.6s;
        animation-fill-mode: both;
        //animation-delay: 0.2s;
        animation-timing-function: ease;
         white-space: pre-line;
    }
    .actif{
            .text{
                animation-name: scaleUpText;
            }
            .img-ono{
                animation-name: scaleUp;
                opacity: 1;
            }
    }


    @keyframes scaleUp {
        0% {
            opacity: 1;
            transform:translate(-50%,-50%) scale(0);
        }
        90% {
            opacity: 1;
        }
        100% {
            opacity: 0;
            transform:translate(-50%,-50%) scale(2);
        }
    }
    @keyframes scaleUpText {
        0% {
            opacity: 1;
            transform:translate(-50%,-50%) scale(0);
        }
        80% {
            opacity: 1;
            
        }
        100% {
            opacity: 0;
            transform:translate(-50%,-50%) scale(1);
        }
    }
</style>
