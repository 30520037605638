<template>
  <div
    class="point"
    :id="'point-'+idPoint"
    v-bind:class="[{ hide: !datas.show,actif:selectable},state]"
    v-bind:style="{transform:'translate('+this.location[0]+'px,'+this.location[1]+'px)'}"
    v-on:click.prevent.stop="onClick();"
  >
    <div class="point-inner">
      <p>{{datas.number}}</p>
    </div>
    <div class="pulse">
      <div></div>
      <div style="animation-delay: 0.33s;"></div>
      <div style="animation-delay: 0.66s;"></div>
    </div>
  </div>
</template>


<script>
export default {
  name: "gamePoints",
  props: {
    datas: Object,
    indStart: Number,
    idPoint: Number
  },
  data: () => {
    return {
      state: "idle",
      clickTimeout: null,
      beforeClickTimeout: null,
      selectable: false,
      startClock: null,
      location: [],
      parent: null
    };
  },
  methods: {
    onClick: function() {
      // console.log("click")
      //test if in clicked time
      if (this.state == "stay") {
        //Clear clicktime timeout
        clearTimeout(this.clickTimeout);

        if (this.selectable) {
          //Click on time
          this.state = "win";
          const timeDif = new Date() - this.startClock;
          let pourc = Math.round(
            ((this.datas.duration * 1000 - timeDif) * 100) /
              (this.datas.duration * 1000)
          );
          this.$emit("points", pourc);
          this.selectable = false;
        } else {
          //Click before or after
          clearTimeout(this.beforeClickTimeout);
          this.state = "loose";
          this.$emit("loosePoint");
          this.selectable = false;
        }
      }
    },
    startTimer: function() {
      //Set timeout for before click time
      this.beforeClickTimeout = setTimeout(() => {
        //Set state to stay, set selectable & set the date
        if (this.state == "stay") {
          this.selectable = true;
          this.startClock = new Date();
        }
        //Set timeout for click time
        this.clickTimeout = setTimeout(() => {
          //If not clicked send loose
          this.selectable = false;
          if (this.state == "stay") {
            this.state = "loose";
            this.$emit("loosePoint");
          }
        }, this.datas.duration * 1000);
      }, this.datas.time * 1000);
    },
    showPoints: function() {
      this.state = "stay";
    },
    replacePoint: function() {
      this.location = [
        this.parent.offsetWidth * this.datas.position[0],
        this.parent.offsetHeight * this.datas.position[1]
      ];
    },
    setSelectable(s) {
      this.selectable = s;
    }
  },
  watch: {
    indStart: function() {
      if (this.datas.show) {
        this.startTimer();
      }
    },
    datas: function() {
      this.state = "stay";
      this.replacePoint();
    }
  },
  mounted: function() {
    this.state = "stay";
    window.addEventListener("resize", this.replacePoint);

    this.parent = document.querySelector("#point-" + this.idPoint).parentNode;
    this.replacePoint();
  }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
* {
  -webkit-tap-highlight-color: transparent;
}
.point {
  position: absolute;
  top: 0;
  left: 0;
  width: 70px;
  height: 70px;
  margin: -35px -35px;
  cursor: pointer;
}
.point-inner {
  background: none;
  width: 100%;
  height: 100%;
  border-radius: 50%;
  transition: transform 0.25s ease, opacity 0.25s ease,
    background-color 0.25s ease;
  border: 2px solid #c9b68f;
  box-sizing: border-box;
  position: relative;

  p {
    //font-family: Druk Text;
    font-style: normal;
    font-weight: 500;
    font-size: 40px;
    //line-height: 67px;
    color: #f2e1ac;
    position: absolute;
    margin-top: -2px;
    top: 50%; /* poussé de la moitié de hauteur du référent */
    transform: translateY(-50%); /* tiré de la moitié de sa propre hauteur */
    text-align: center;
    width: 100%;
    // pointer-events: none;
  }
}
.hide {
  opacity: 0;
   pointer-events: none;
}
.actif .point-inner {
  background: #707a89;
  border: none;
}
.win {
  visibility:hidden;
  .point-inner {
    transform: scale(0);
  }
  .pulse {
    transform: scale(0);
  }
}
.loose {
  visibility:hidden;
  .point-inner {
    transform: scale(0);
  }
  .pulse {
    transform: scale(0);
  }
}
.idle {
  visibility:hidden;
  .point-inner {
    transform: scale(0);
  }
  .pulse {
    transform: scale(0);
  }
}
p {
  margin: 0;
}
.pulse {
  display: none;
  transition: transform 0.25s ease;
}
.actif .pulse {
  display: block;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  div {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%) scale(1);
    border: 1px solid #707a89;
    border-radius: 50%;
    animation-name: pulse;
    animation-duration: 1s;
    animation-iteration-count: infinite;
    animation-timing-function: linear;
  }
}
@keyframes pulse {
  0% {
    transform: translate(-50%, -50%) scale(1);
    opacity: 1;
  }
  100% {
    transform: translate(-50%, -50%) scale(1.42);
    opacity: 0;
  }
}
@media screen and (max-width: 768px) {
  .point {
    width: 60px;
    height: 60px;
      margin: -30px -30px;
  }
  .point-inner {
    p {
      font-size: 30px;
    }
  }
}
</style>
