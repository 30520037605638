<template>
    <div class="home">
        <LangSwitcher v-if="langControl" />
        <div class="diptych-container">
            <div class="diptych-item content-section">
                <img class="background-image" :class="{show : gameDone }" src="../assets/endscreen.png" alt="">

                <transition name="text-banner-transition">
                    <TextBanner v-if="gameDone" :title="$t('game.win')" />
                </transition>

                <transition name="content-transition">
                    <div
                        class="content-container description-layout"
                        :class="[{'pointer-events-none' : gameIsRunning}]"
                        v-if="!displayForm"
                    >
                        <Header
                            v-if="!gameDone"
                            :gameOn="gameIsRunning"
                            :tutoEnded="tutoEnded"
                        />
                        <transition name="game-score-transition">
                            <GameScore
                                v-if="gameIsRunning"
                                :points="points"
                                :multi="multiplicateur"
                            ></GameScore>
                        </transition>

                        <transition name="final-score-transition">
                            <FinalScore v-if="gameDone" v-on:restart="reStartApp()" :score="points" :playAgainCTA="$t('end.playAgain')" />
                        </transition>

                        <transition name="intro-transition">
                            <div class="intro" v-if="!gameStarted">
                                <img class="crown" src="../assets/jpg-crown.png" alt="">
                                <img class="main-title" :src="`/img/main-title-${$i18n.locale}.svg`" alt="">

                                <div class="locales-hub-container" v-if="!localeChosen">
                                    <LocalesHub @localeChosen="localeChosen = !localeChosen" :locales="['fr', 'en', 'es']"/>
                                </div>

                                <ButtonStart
                                    v-on:click="buttonStartClickHandler()"
                                    :loadingLabel="$t('intro.loading')"
                                    :label="$t('intro.start')"
                                    :progress="loadProgress"
                                    v-if="localeChosen"
                                />

                                <div class="description-container" v-if="localeChosen">
                                    <p class="description" v-html="$t('intro.sephoraPOS.description')"></p>
                                </div>
                            </div>
                        </transition>

                        <transition name="win-transition">
                            <div class="win" v-show="gameDone">
                                <BottleSprite />
                                <Header />

                                <div>
                                    <p class="description" v-html="$t('end.sephoraPOS.raffleTitle')"></p>
                                </div>


                                <div class="sephora-button-wrapper">
                                    <Button
                                        v-if="$i18n.locale === 'fr'"
                                        :className="`full`"
                                        v-on:click="buttonSampleClickHandler()"
                                        :label="`Je tente ma chance`"
                                    />
                                    <Button
                                        v-if="$i18n.locale === 'en'"
                                        :className="`full`"
                                        v-on:click="buttonSampleClickHandler()"
                                        :label="`I try my luck`"
                                    />
                                    <Button
                                        v-if="$i18n.locale === 'es'"
                                        :className="`full`"
                                        v-on:click="buttonSampleClickHandler()"
                                        :label="`Pruebo mi suerte`"
                                    />
                                </div>


                                <div>
                                    <p class="description smaller" v-html="$t('end.sephoraPOS.raffleDescription')"></p>
                                </div>


                            </div>
                        </transition>

                    </div>
                </transition>

                <transition name="form-transition">
                    <div class="content-container" v-show="displayForm">
                        <span v-on:click="closeForm()" class="close-button"></span>
                        <RegisterForm v-if="!formURL" />
                        <EmbedForm v-if="formURL && gameDone" :url="$t('end.sephoraPOS.formURL')" />
                    </div>
                </transition>

            </div>

            <div class="diptych-item game-section" :class="{'game-done' : gameDone}">
                <transition name="video-transition">
                    <VideoSplashScreen v-show="!gameStarted" />
                </transition>
                <JPGGame
                    ref="JPGGame"
                    :moveNumber="12"
                    v-on:threeLoadingCallback="threeLoadingCallback"
                    v-on:threeLoadedCallback="threeLoadedCallback"
                    v-on:endGameCallback="endGameCallback"
                    v-on:updatePointCallback="updatePointCallback"
                    v-on:endTuto="endTuto"
                />
            </div>
        </div>
    </div>
</template>

<script>
import Header from "@/components/Header.vue"
import VideoSplashScreen from "@/components/VideoSplashScreen.vue"
import JPGGame from '@/components/Game.vue'
import GameScore from '@/components/game-components/GameScore.vue'
import ButtonStart from '@/components/ui/ButtonStart.vue'
import Button from '@/components/ui/Button.vue'
import TextBanner from '@/components/ui/TextBanner.vue'
import BottleSprite from '@/components/ui/BottleSprite.vue'
import FinalScore from '@/components/FinalScore.vue'
import RegisterForm from '@/components/RegisterForm.vue'
import EmbedForm from '@/components/EmbedForm.vue'
import LangSwitcher from '@/components/LangSwitcher.vue'
import LocalesHub from '@/components/LocalesHub.vue'
import { StatusBar } from '@capacitor/status-bar';

export default {
    name: "Home",
    data() {
        return {
            gameStarted: false,
            gameDone: true,
            gameIsRunning: false,
            tutoEnded: false,
            displayForm: false,
            points:0,
            multiplicateur:0,
            loadProgress: null,
            routeName: null,
            sampleActive: null,
            formURL: null,
            discoverURL: null,
            subscribeURL: null,
            langControl: false,
            activityTimeout:null,
            localeChosen: false
        }
    },
    components: {
        Button,
        Header,
        ButtonStart,
        VideoSplashScreen,
        TextBanner,
        JPGGame,
        GameScore,
        BottleSprite,
        FinalScore,
        RegisterForm,
        EmbedForm,
        LangSwitcher,
        LocalesHub
    },
    methods: {
        start() {
            this.$refs.JPGGame.startGame();
            this.gameStarted = !this.gameStarted
            this.gameIsRunning = true
            this.$audio.playAudio("background")
        },
        reStartApp() {
            this.gameDone = false
            this.gameStarted = false
            this.tutoEnded = false
            this.gameIsRunning = false
            this.displayForm = false
            this.points = 0
            this.multiplicateur = 0
            this.localeChosen = false
        },
        threeLoadingCallback(progress){
            this.loadProgress = progress
        },
        threeLoadedCallback(){
            console.log("loaded")
        },
        updatePointCallback(_points,_multi){
            this.points = _points
            this.multiplicateur = _multi
        },
        endGameCallback(points){
            this.points = points
            this.gameDone = true
            this.gameIsRunning = false
        },
        buttonStartClickHandler() {

            if (this.loadProgress < 100)
                return;

            this.start()
        },
        buttonSampleClickHandler() {
            this.displayForm = true
        },
        closeForm() {
            this.displayForm = false
        },
        endTuto(){
            this.tutoEnded = true
        },
        resetTimer() {
            window.clearTimeout(this.activityTimeout)
            this.startTimer();
        },
        startTimer() {
            // window.setTimeout returns an Id that can be used to start and stop a timer
            this.activityTimeout = window.setTimeout(this.doInactive, 60000*2);
        }, doInactive(){
            this.reStartApp();

            // does whatever you need it to actually do - probably signs them out or stops polling the server for info
        }, setupTimers () {
            document.addEventListener("mouseup", this.resetTimer, false);
            this.startTimer();
        },
        async hideStatusBar (){
            await StatusBar.hide();
        }
    },
    mounted() {
        // I set the value to true at the beginning to trick the browser into thinking
        // it needs to download the sprite used in <BottleSprite>
        // In order to have a smooth transition after game is done
        this.gameDone = false


        // Addaptation configuration
        this.routeName = this.$route.name
        this.sampleActive = this.$route.meta.sample
        this.formURL = this.$route.meta.formURL
        this.discoverURL = this.$route.meta.discoverURL
        this.subscribeURL = this.$route.meta.subscribeURL
        this.langControl = this.$route.meta.langControl
        this.setupTimers();
        this.hideStatusBar();
    },
};



</script>

<style lang="scss">

.sephora-button-wrapper {
    .button-link.full {
        margin: 10px 0!important;
    }
}

.locales-hub-container {
    width: 80%;
    max-width: 320px;
    margin: auto;
}

.home {
    width: 100%;
    height: 100%;
}

.diptych-container {
    display: flex;
    align-items: stretch;
    height: 100%;

    .diptych-item {
        position: relative;
        width: 50%;
        height: 100%;

        &.content-section {
            background: linear-gradient(180deg, #192935 0%, #121C25 100%);

            .header {
                margin-top: 50px;
            }

            .background-image {
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
                object-fit: cover;
                opacity: 0;

                transition: all 1000ms $easeInSine;

                &.show {
                    opacity: 1;
                }
            }

            .content-container {
                position: relative;
                height: 100%;
                z-index: 5;

                .close-button {
                    position: absolute;
                    z-index: 999;
                    top: 25px;
                    left: 25px;
                    width: 30px;
                    height: 30px;
                    display: block;

                    &::after, &::before {
                        content: '';
                        position: absolute;
                        top: 14px;
                        left: 0;
                        width: 100%;
                        height: 2px;
                        background-color: #ffffff;
                    }

                    &::after {
                        transform: rotate(45deg);
                    }

                    &::before {
                        transform: rotate(-45deg);
                    }
                }

                &.description-layout {
                    .header .logo-scandal {
                        max-width: 200px;
                    }

                    .header .logo-jpg {
                        max-width: 100px;
                    }

                    .intro .topline {
                        font-size: 16px;
                    }

                    .intro .crown {
                        width: 10vh;
                    }

                    .intro .main-title {
                        width: 24vh;
                    }
                }
            }
        }
    }

}

.intro {
    margin: 15px auto 35px auto;

    .topline {
        color: $color-sorrel-brown;
        font-size: 20px;
        line-height: normal;
        text-transform: uppercase;
        margin-bottom: 40px;
    }

    .crown {
        max-width: 115px;
        width: 12vh;
        margin: 0 auto 30px auto;
    }

    .main-title {
        width: 30vh;
        max-width: 280px;
        margin: 0 auto 35px auto;
    }

    .description-container {
        margin-top: 30px;
        padding: 0 80px;
    }

    .description {
        font-size: 24px;
        line-height: 30px;
        font-weight: 500;
        text-transform: uppercase;
        color: $color-sorrel-brown;
    }

}

.diptych-container .diptych-item.content-section .win .header {
    margin-top: -70px;
}

.win {

    .description {
        font-size: 18px;
        line-height: 23px;
        text-transform: uppercase;
        color: $color-sorrel-brown;
        font-weight: 400;
        text-align: center;
        padding: 0 35px;
        margin: 20px auto;

        &.smaller {
            font-size: 13px;
            line-height: 18px;
        }

        span.bigger {
            font-size: 21px;
            line-height: 26px;
            display: block;
        }
    }

    .sprite-container {
        transform: scale(0.55) translateY(-145px);
    }

    .header {
        margin-top: -200px!important;
    }


}

// Video transition
.video-transition-enter-active {
    transition: all 800ms $easeOutCubic;
}
.video-transition-leave-active {
    transition: all 650ms $magikMotion 1000ms;
}
.video-transition-enter, .video-transition-leave-to {
    transform: translateX(100%);
}

// Intro transition
.intro-transition-enter-active {
    transition: all 800ms $authenticMotion;
}
.intro-transition-leave-active {
    transition: all 650ms $easeInCubic;
}
.intro-transition-enter, .intro-transition-leave-to {
    opacity: 0;
    transform: translateY(-100%);
}

// Content transition
.content-transition-enter-active {
    transition: all 800ms $easeOutCubic 900ms;
    position: absolute!important;
    left: 0!important;
    top: 0!important;
    width: 100%!important;
}
.content-transition-leave-active {
    transition: all 800ms $easeInCubic;
}
.content-transition-enter {
    transform: translateY(-100%);
}

.content-transition-leave-to {
    transform: translateY(-100%);
}

.form-transition-enter-active {
    transition: all 800ms $easeOutCubic 900ms;
}
.form-transition-leave-active {
    transition: all 800ms $easeInCubic;
}
.form-transition-enter {
    transform: translateY(100%);
}

.form-transition-leave-to {
    position: fixed;
    top: 0;
    left: 0;
    transform: translateY(100%);
}


// Game score transition
.game-score-transition-enter-active {
    transition: all 750ms $easeOutCubic 500ms;
}
.game-score-transition-leave-active {
    transition: all 800ms $easeInOutSine;
}
.game-score-transition-enter {
    top: 100%;
    opacity: 0;
}

.game-score-transition-leave-to {
    opacity: 0;
}

// Game score transition
.text-banner-transition-enter-active {
    transition: all 750ms $easeOutCubic 1000ms;
}
.text-banner-transition-leave-active {
    transition: all 650ms $easeInCubic;
}
.text-banner-transition-enter, .text-banner-transition-leave-to {
    opacity: 0;
}

// Game score transition
.final-score-transition-enter-active {
    transition: all 750ms $easeOutCubic 1000ms;
}
.final-score-transition-leave-active {
    transition: all 650ms $easeInCubic;
}
.final-score-transition-enter, .final-score-transition-leave-to {
    opacity: 0;
    transform: translateY(-200px);
}

// Game score transition
.win-transition-enter-active {
    transition: all 800ms $easeOutCubic 2500ms;
}
.win-transition-leave-active {
    transition: all 650ms $easeInCubic;
}
.win-transition-enter, .win-transition-leave-to {
    opacity: 0;
    transform: translateY(100%);
}


@media screen and (max-height: 800px) and (min-width: 768px) {
    .intro .main-title {
        margin-bottom: 30px;
    }

    .diptych-container .diptych-item.content-section .header {
        margin-top: 30px;
    }

    .diptych-container .diptych-item.content-section .win .header {
        margin-top: -110px;
    }

    .win .button-link.full {
        margin: 30px auto 25px auto;
    }
}

@media screen and (max-height: 700px) and (min-width: 768px) {
    .intro .main-title {
        margin-bottom: 30px;
    }

    .intro .topline {
        display: none;
    }
}

// Ipad pro vertical
@media screen and (max-width: 1024px) and (orientation: portrait) {
    .diptych-container {
        display: block;

        .diptych-item {
            width: 100%;

            &.content-section {
                padding-top: 50px;

                .header {
                    margin-top: 0;
                }
            }

            &.game-section {
                transform: translateY(-100%);
            }

            &.game-done.game-section {
                transform: translateY(0);
            }
        }
    }
}

@include max-width(m) {

    .intro {
        .description-container {
            margin-top: 30px;
            padding: 0 80px;
        }

        .description {
            font-size: 20px;
            line-height: 26px;
            font-weight: 500;
            text-transform: uppercase;
            color: $color-sorrel-brown;
        }
    }

    .win {

        .description {
            font-size: 14px;
            line-height: 18px;
            text-transform: uppercase;
            color: $color-sorrel-brown;
            font-weight: 400;
            text-align: left;
            padding: 0 35px;
            margin-top: 20px;

            span {
                display: block;
                margin-top: 5px;
                font-size: 9px;
                line-height: 11px;
            }
        }
    }

}


// Mobile 768px

@include max-width(s) {
    .diptych-container {
        display: block;

        .diptych-item {
            width: 100%;

            &.content-section {
                padding-top: 30px;

                .header {
                    margin-top: 0;
                }
            }

            &.game-section {
                transform: translateY(-100%);
            }

            &.game-done.game-section {
                transform: translateY(0);
            }
        }
    }

    .intro {
        margin: 25px auto;

        .topline {
            display: none;
        }

        .crown {
            max-width: 80px;
            margin: 0 auto 20px auto;
        }

        .main-title {
            max-width: 230px;
            margin: 0 auto 30px auto;
        }

    }

    .win {

        .header {
            margin-bottom: 30px;
        }
    }

    .diptych-container .diptych-item.content-section .win .header {
        margin-top: -40px;
    }

    // Video transition
    .video-transition-enter-active {
        transition: all 800ms $easeOutCubic;
    }
    .video-transition-leave-active {
        transition: all 800ms $easeInCubic 1000ms;
    }
    .video-transition-enter, .video-transition-leave-to {
        transform: translateY(-100%);
    }
}

@include max-width(xxs) {
    .intro .main-title {
        margin: 0 auto 45px auto;
    }

    .diptych-container .diptych-item.content-section .win .header {
        margin-top: -60px;
    }
}
</style>
