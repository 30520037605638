/* eslint-disable */
import * as THREE from 'three'

// import Stats from 'three/examples/jsm/libs/stats.module.js';
// import { GUI } from 'three/examples/jsm/libs/dat.gui.module.js';

import { GLTFLoader } from 'three/examples/jsm/loaders/GLTFLoader.js';
import { EffectComposer } from 'three/examples/jsm/postprocessing/EffectComposer.js'
import { RenderPass } from 'three/examples/jsm/postprocessing/RenderPass.js'
import { UnrealBloomPass } from 'three/examples/jsm/postprocessing/UnrealBloomPass'
import { SMAAPass } from 'three/examples/jsm/postprocessing/SMAAPass.js'

let canvas, clock, gui, mixer, actions, activeAction, previousAction;
let camera, scene, renderer, model,effectComposer,renderPass,unrealBloomPass;
var screenShake = ScreenShake();
const api = { state: 'Idle' };
let loadingCallback,loadedCallback



export function init(publicPath,_canvas,_loadingCallback,_loadedCallback) {
    canvas = _canvas


    loadingCallback = _loadingCallback
    loadedCallback = _loadedCallback

    camera = new THREE.PerspectiveCamera( 45,  canvas.parentNode.offsetWidth / canvas.parentNode.offsetHeight, 0.25, 100 );
    camera.position.set( - 0, 1.5, 3.5 );
    camera.lookAt( new THREE.Vector3( 0, 1.1, 0 ) );

    scene = new THREE.Scene();


    clock = new THREE.Clock();

    /**
     * Loader
     */
    const manager = new THREE.LoadingManager();
    manager.onProgress = function ( url, itemsLoaded, itemsTotal ) {
        const progressRatio = (itemsLoaded / itemsTotal)*100
        loadingCallback(progressRatio)
    };
    manager.onLoad = function ( ) {
        loadedCallback()
    };

    var textureURLs = [  // URLs of the six faces of the cube map 
		publicPath+'game/image/cubemap/px.jpg',
		publicPath+'game/image/cubemap/nx.jpg',
		publicPath+'game/image/cubemap/py.jpg',
		publicPath+'game/image/cubemap/ny.jpg',
		publicPath+'game/image/cubemap/pz.jpg',
		publicPath+'game/image/cubemap/nz.jpg'
   ];
   let skyTexture  = []
   let skyMaterial = []
   for (var i = 0; i < 6; i++) {
        skyTexture[i] = new THREE.TextureLoader(manager).load(textureURLs[i])
        skyTexture[i].encoding = THREE.sRGBEncoding
        skyMaterial.push( new THREE.MeshBasicMaterial( {
            side: THREE.BackSide,  // IMPORTANT: To see the inside of the cube, back faces must be rendered!
            map: skyTexture[i]
        } ) );
    }

    // lights
    const hemiLight = new THREE.HemisphereLight( 0xffffff, 0x444444,0.7);
    hemiLight.position.set( 0, 20, 0 );
    scene.add( hemiLight );

    const dirLight = new THREE.DirectionalLight( 0xffffff,0.1 );
    dirLight.position.set( 0, 20, 10 );
    dirLight.castShadow = true;
    dirLight.shadow.camera.top = 2;
    dirLight.shadow.camera.bottom = - 2;
    dirLight.shadow.camera.left = - 2;
    dirLight.shadow.camera.right = 2;
    dirLight.shadow.camera.near = 0.1;
    dirLight.shadow.camera.far = 40;
    scene.add( dirLight );

    // ground

    const geometry = new THREE.BoxGeometry( 75, 75, 75 );
    const mesh = new THREE.Mesh( geometry, skyMaterial );
    mesh.position.z = -20;
    mesh.position.y = 0;
    scene.add( mesh );

    const plane = new THREE.Mesh( new THREE.PlaneGeometry( 2000, 2000 ), new THREE.ShadowMaterial({opacity: 0.4}) );
    plane.rotation.x = - Math.PI / 2;
    plane.receiveShadow = true;
    scene.add( plane );

    // model

    const loader = new GLTFLoader(manager);
    loader.load( publicPath+'game/gltf/parker1.glb', function ( gltf ) {
        
        model = gltf.scene;
        scene.add( model );
        model.traverse( function ( object ) {

            if ( object.isMesh ) object.castShadow = true;

        } );
        createGUI( model, gltf.animations );

    }, undefined, function ( e ) {

        console.error( e );

    } );


    renderer = new THREE.WebGLRenderer( { antialias: true,
        canvas :canvas
     } );
    renderer.setPixelRatio( Math.min(window.devicePixelRatio, 2) );
    renderer.setSize( canvas.parentNode.offsetWidth, canvas.parentNode.offsetHeight );
    renderer.outputEncoding = THREE.sRGBEncoding;
    renderer.shadowMap.enabled = true;
    // renderer.toneMapping = THREE.CineonToneMapping
    // renderer.toneMappingExposure = 1.5


     /**
     * Post processing
     */
    let RenderTargetClass = null

    if(renderer.getPixelRatio() === 1 && renderer.capabilities.isWebGL2)
    {
        RenderTargetClass = THREE.WebGLMultisampleRenderTarget
    }
    else
    {
        RenderTargetClass = THREE.WebGLRenderTarget
    }

    const renderTarget = new RenderTargetClass(
        800,
        600,
        {
            minFilter: THREE.LinearFilter,
            magFilter: THREE.LinearFilter,
            format: THREE.RGBAFormat,
            encoding: THREE.sRGBEncoding
        }
    )
    effectComposer = new EffectComposer(renderer,renderTarget)
    effectComposer.setPixelRatio(Math.min(window.devicePixelRatio, 2))
    effectComposer.setSize(canvas.parentNode.offsetWidth,canvas.parentNode.offsetHeight)

    renderPass = new RenderPass(scene, camera)
    effectComposer.addPass(renderPass)

    // Antialias pass
    if(renderer.getPixelRatio() === 1 && !renderer.capabilities.isWebGL2)
    {
        const smaaPass = new SMAAPass()
        effectComposer.addPass(smaaPass)

        console.log('Using SMAA')
    }

    unrealBloomPass = new UnrealBloomPass()
    effectComposer.addPass(unrealBloomPass)
    unrealBloomPass.strength = 0.3
    unrealBloomPass.radius = 1
    unrealBloomPass.threshold = 0.5



    /**
     * Stats
     */
    // stats
    // stats = new Stats();
    // canvas.parentNode.appendChild( stats.dom );
    window.addEventListener( 'resize', onWindowResize );
}

function createGUI( model, animations ) {

    const states = [ 'Idle', 'Victory'];
    const emotes = [ 'Boxing', 'Jumping', 'Hook', 'Dodging', 'Squat' ];

    // gui = new GUI();
    // gui.closed=true;
    mixer = new THREE.AnimationMixer( model );

    actions = {};

    for ( let i = 0; i < animations.length; i ++ ) {

        const clip = animations[ i ];
        const action = mixer.clipAction( clip );
        actions[ clip.name ] = action;
        if ( emotes.indexOf( clip.name ) >= 0 || states.indexOf( clip.name ) >= 4 ) {
            action.clampWhenFinished = true;
            action.loop = THREE.LoopOnce;
        }

    }

    // states

    // const statesFolder = gui.addFolder( 'States' );

    // const clipCtrl = statesFolder.add( api, 'state' ).options( states );

    // clipCtrl.onChange( function () {

    //     fadeToAction( api.state, 0.5 );

    // } );

    // statesFolder.open();

    // emotes

    // const emoteFolder = gui.addFolder( 'Emotes' );

    function createEmoteCallback( name ) {

        api[ name ] = function () {

            fadeToAction( name, 0.2 );

            mixer.addEventListener( 'finished', restoreState );

        };

        // emoteFolder.add( api, name );

    }

    function restoreState() {

        mixer.removeEventListener( 'finished', restoreState );

        fadeToAction( api.state, 0.5 );

    }

    for ( let i = 0; i < emotes.length; i ++ ) {

        createEmoteCallback( emotes[ i ] );

    }

    // emoteFolder.open();

    activeAction = actions[ 'Idle' ];
    activeAction.play();

}
    function restoreState() {

        mixer.removeEventListener( 'finished', restoreState );

        fadeToAction( api.state, 0.5 );

    }
    export function startAction(name,duration,speed){
        fadeToAction( name, duration ,speed);
        mixer.addEventListener( 'finished', restoreState );
    }

    function fadeToAction( name, duration ,speed =1) {

        previousAction = activeAction;
        activeAction = actions[ name ];

        if ( previousAction !== activeAction ) {

            previousAction.fadeOut( duration );

        }

        activeAction
            .reset()
            .setEffectiveTimeScale( 1/speed )
            .setEffectiveWeight( 1 )
            .fadeIn( duration )
            .play();

    }

    function onWindowResize() {

        camera.aspect = canvas.parentNode.offsetWidth / canvas.parentNode.offsetHeight;
        camera.updateProjectionMatrix();

        renderer.setSize(  canvas.parentNode.offsetWidth, canvas.parentNode.offsetHeight );
        effectComposer.setSize(canvas.parentNode.offsetWidth, canvas.parentNode.offsetHeight )

    }

    /**
     * EXPORT
     */

    export function animate() {
        const dt = clock.getDelta();
        const elapsedTime = clock.getElapsedTime()
        unrealBloomPass.strength  = map(Math.cos(elapsedTime*7),-1,1,0,0.15)+0.3
        if ( mixer ) mixer.update( dt );

        requestAnimationFrame( animate );

        //renderer.render( scene, camera );
        screenShake.update(camera);
        effectComposer.render()
        // stats.update();

    }

    export function setSpeed(speed){
        const keys = Object.keys(actions);
        for(let i=0;i<keys.length;i++){
            actions[keys[i]].setEffectiveTimeScale( 1/speed )
        }
    }


    export function shake(){
        screenShake.shake( camera, new THREE.Vector3(0, 0, 0.40), 300 /* ms */ );
    }

    /**
     * UTILS
     */

    const map = (value, x1, y1, x2, y2) => (value - x1) * (y2 - x2) / (y1 - x1) + x2;
    function ScreenShake() {

        return {

            // When a function outside ScreenShake handle the camera, it should
            // always check that ScreenShake.enabled is false before.
            enabled: false,

            _timestampStart: undefined,

            _timestampEnd: undefined,

            _startPoint: undefined,

            _endPoint: undefined,



            // update(camera) must be called in the loop function of the renderer,
            // it will re-position the camera according to the requested shaking.
            update: function update(camera) {
                if ( this.enabled == true ) {
                    const now = Date.now();
                    if ( this._timestampEnd > now ) {
                        let interval = (Date.now() - this._timestampStart) / 
                            (this._timestampEnd - this._timestampStart) ;
                        this.computePosition( camera, interval );
                    } else {
                        camera.position.copy(this._startPoint);
                        this.enabled = false;
                    };
                };
            },



            // This initialize the values of the shaking.
            // vecToAdd param is the offset of the camera position at the climax of its wave.
            shake: function shake(camera, vecToAdd, milliseconds) {
                this.enabled = true ;
                this._timestampStart = Date.now();
                this._timestampEnd = this._timestampStart + milliseconds;
                this._startPoint = new THREE.Vector3().copy(camera.position);
                this._endPoint = new THREE.Vector3().addVectors( camera.position, vecToAdd );
            },




            computePosition: function computePosition(camera, interval) {

                // This creates the wavy movement of the camera along the interval.
                // The first bloc call this.getQuadra() with a positive indice between
                // 0 and 1, then the second call it again with a negative indice between
                // 0 and -1, etc. Variable position will get the sign of the indice, and
                // get wavy.
                if (interval < 0.4) {
                    var position = this.getQuadra( interval / 0.4 );
                } else if (interval < 0.7) {
                    var position = this.getQuadra( (interval-0.4) / 0.3 ) * -0.6;
                } else if (interval < 0.9) {
                    var position = this.getQuadra( (interval-0.7) / 0.2 ) * 0.3;
                } else {
                    var position = this.getQuadra( (interval-0.9) / 0.1 ) * -0.1;
                }
                
                // Here the camera is positioned according to the wavy 'position' variable.
                camera.position.lerpVectors( this._startPoint, this._endPoint, position );
            },

            // This is a quadratic function that return 0 at first, then return 0.5 when t=0.5,
            // then return 0 when t=1 ;
            getQuadra: function getQuadra(t) {
                return 9.436896e-16 + (4*t) - (4*(t*t)) ;
            }

        };

    };
/* eslint-enable */