<template>
  <div class="game_three">
    <canvas class="webgl"></canvas>
  </div>
</template>


<script>
import { init, animate, startAction, setSpeed,shake } from "../../assets/game/3d.js";

export default {
  name: "gameThree",
  props: {
    currentAction: String,
    speed: Number
  },
  data: () => {
    return {
      publicPath: process.env.BASE_URL
    };
  },
  methods: {
    loadingCallback: function(pourc) {
      this.$emit("threeLoadingCallback", pourc);
    },
    loadedCallback: function() {
      this.$emit("threeLoadedCallback");
    },
    shakeGame:function(){
      shake()
    }
  },
  watch: {
    currentAction: function(action) {
      //Start action in threejs
      if (action != null) {
        startAction(action, 1.0, this.speed);
      }
    },
    speed: function(newSpeed) {
      setSpeed(newSpeed);
    }
  },
  mounted: function() {
    //Init treejs
    init(
      this.publicPath,
      document.querySelector(".webgl"),
      this.loadingCallback,
      this.loadedCallback
    );
    animate();
  }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.game_three {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 0;
}
</style>
