import { render, staticRenderFns } from "./Argentina.vue?vue&type=template&id=3df53552&scoped=true&"
import script from "./Argentina.vue?vue&type=script&lang=js&"
export * from "./Argentina.vue?vue&type=script&lang=js&"
import style0 from "./Argentina.vue?vue&type=style&index=0&id=3df53552&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3df53552",
  null
  
)

export default component.exports