<template>

  <div class="textFade" v-bind:class="[{actif:show},animType]">
      <p class="text" :style="styleText">{{text}}</p>
      <p class="text-border" :style="styleBorder">{{text}}</p>
  </div>
</template>


<script>
export default {
    name: 'TextFadeScale',
    props: {
      text: String,
      size: Number,
      duration:Number,
      color: String,
      animType:String
    },
    data:()=>{
        return{
            styleText:{
                fontSize:"",
                color:""
            },
            styleBorder:{
                fontSize:"",
                webkitTextStroke:""
            },
            show:false
        }
    },
    methods: {
        setStyle(){
            this.styleText.fontSize = this.size+'px';
            this.styleText.color = this.color;
            this.styleText.animationDuration = this.duration+'s';
            this.styleBorder.webkitTextStroke = '1px '+this.color;
            this.styleBorder.fontSize = this.size+'px';
            this.styleBorder.animationDuration = (this.duration*0.6)+'s';
            this.styleBorder.animationDelay = (this.duration*0.2)+'s';
        }
    },
    watch: {
        text: function(t){
            this.show=false;
            let self = this;
            setTimeout(function(){
                if(t!=""){
                    self.show = true;
                }
                
            },20)
        },
        color:function(){
            this.setStyle()
        },
        size:function(){
            this.setStyle()
        }
    },  
    mounted:function(){
        this.setStyle()
        if(this.text!=""){
            this.show = true;
        }
    },
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
    .text{
        position: absolute;
        top : 50%;
        left: 50%;
        transform:translate(-50%,-50%);
        z-index: 10;
        opacity: 0;
        animation-duration: 1s;
        animation-fill-mode: both;
        animation-timing-function: ease;
         white-space: pre-line;
    }
    .text-border{
        position: absolute;
        top : 50%;
        left: 50%;
        transform:translate(-50%,-50%);
        z-index: 5;
        opacity : 0;
        color:rgba(0,0,0,0);
        animation-duration: 0.6s;
        animation-fill-mode: both;
        animation-delay: 0.2s;
        animation-timing-function: ease;
         white-space: pre-line;
    }
    .actif{
        &.animScale{
            .text{
                animation-name: scaleUpText;
            }
            .text-border{
                animation-name: scaleUp;
                opacity: 1;
            }
        }
        &.animFade{
            .text{
            animation-name: fadeInBottom;
            }
            .text-border{
                opacity : 0;
            }
        }
    }


    @keyframes scaleUp {
        0% {
            opacity: 1;
            transform:translate(-50%,-50%) scale(0);
        }
        45% {
            opacity: 1;
        }
        100% {
            opacity: 0;
            transform:translate(-50%,-50%) scale(2);
        }
    }
    @keyframes scaleUpText {
        0% {
            opacity: 1;
            transform:translate(-50%,-50%) scale(0);
        }
        65% {
            opacity: 1;
            
        }
        100% {
            opacity: 0;
            transform:translate(-50%,-50%) scale(1);
        }
    }
    @keyframes fadeInBottom {
        0% {
            opacity: 0;
            transform:translate(-50%,-60%);
        }
        70% {
            opacity: 1;
            transform:translate(-50%,-50%);
        }
        100% {
            opacity: 0;
            transform:translate(-50%,-50%);
        }
    }
</style>
