<template>
    <div class="not-found-container">
        <div>
            <p>404</p>
            <h1>
                Oops, there is nothing to see here.
            </h1>
            <router-link to="/">Back to the lobby</router-link>
        </div>
    </div>
</template>

<script>
export default {
    name: "PageNotFound"
}
</script>

<style scoped lang="scss">
    .not-found-container {
        display: flex;
        width: 100%;
        height: 100%;
        text-align: center;
        justify-content: center;
        background: linear-gradient(180deg, #192935 0%, #121C25 100%);
        color: $color-sorrel-brown;


        & > div {
            align-self: center;
        }

        p {
            font-size: 100px;
        }

        h1 {
            font-size: 50px;
            line-height: 60px;
            text-transform: uppercase;
            margin: 25px 0;
        }

        a {
            color: $color-sorrel-brown;
            font-size: 20px;
            line-height: 30px;

            &:visited {
                color: $color-sorrel-brown;
            }
        }
    }
</style>