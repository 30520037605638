<template>
  <div class="countdown">
    <textFadeScale
      class="countdown-text"
      v-bind:text="textCountdown"
      v-bind:size="200"
      v-bind:duration="1"
      v-bind:color="'#C5000D'"
      v-bind:animType="'animScale'"
    />
  </div>
</template>


<script>
import textFadeScale from "./TextFadeScale.vue";
export default {
  name: "GameCountdown",
  components: {
    textFadeScale
  },
  data: () => {
    return {
      countdown: 3,
      textCountdown: "3"
    };
  },
  methods: {
    startCountdown() {
      setTimeout(this.updateCountdown, 1000);
    },
    updateCountdown() {
      if (this.countdown > 0) {
        this.countdown--;
        if (this.countdown == 0) {
          this.textCountdown = "GO!";
        } else {
          this.textCountdown = this.countdown.toString();
        }
        setTimeout(this.updateCountdown, 1000);
      } else {
        this.endCountdown();
      }
    },
    endCountdown() {
      this.$emit("endCountdown");
    }
  },
  mounted: function() {
    this.startCountdown();
  }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.countdown {
  position: relative;
  z-index: 500;
  height: 100%;
  background: rgba(18, 28, 37, 0.6);
  animation-name: backgroundFade;
  animation-duration: 4s;
  animation-timing-function: ease;
  animation-fill-mode: forwards;
}
.countdown-text {
  width: 100%;
  height: 100%;
}
@keyframes backgroundFade {
  from {
    background: rgba(18, 28, 37, 0.6);
  }
  to {
    background: rgba(18, 28, 37, 0);
  }
}
</style>
