<template>
    <div class="home">

        <LangSwitcher v-if="langControl" />
        <span class="mute-icon" :class="{'muted' : audioMuted}" v-on:click="muteAudio()" v-if="audioControl">
            <img src="../assets/sound-icon.svg" alt="">
        </span>
        <div class="diptych-container">
            <div class="diptych-item content-section">
                <img class="background-image" :class="{show : gameDone }" src="../assets/endscreen.png" alt="">

                <transition name="text-banner-transition">
                    <TextBanner v-if="gameDone" :title="$t('game.win')" />
                </transition>

                <transition name="content-transition">
                    <div
                        class="content-container" 
                        :class="[{'pointer-events-none' : gameIsRunning}, {'description-eretailer' : $te(`intro.eretailerDescription.${routeName}`)}]"
                        v-if="!displayForm"
                    >

                        <Header
                                v-if="!gameDone"
                                :gameOn="gameIsRunning"
                                :tutoEnded="tutoEnded"
                        ></Header>
                        <transition name="game-score-transition">
                            <GameScore
                                    v-if="gameIsRunning"
                                    :points="points"
                                    :multi="multiplicateur"
                            />
                        </transition>

                        <transition name="final-score-transition">
                            <FinalScore v-if="gameDone" v-on:restart="reStartApp()" :score="points" :playAgainCTA="$t('end.playAgain')" />
                        </transition>

                        <transition name="intro-transition">
                            <div :class="`intro ${$i18n.locale}`" v-if="!gameStarted">
                                <p class="topline">
                                    {{$t('intro.present')}}
                                </p>
                                <img class="crown" src="../assets/jpg-crown.png" alt="">
                                <img class="main-title" :src="`/img/main-title-${$i18n.locale}.svg`" alt="">

                                <ButtonStart
                                    v-on:click="buttonStartClickHandler()"
                                    :loadingLabel="$t('intro.loading')"
                                    :label="$t('intro.start')"
                                    :progress="loadProgress"
                                />

                                <div class="description-container" v-if="$te(`intro.eretailerDescription.${routeName}`)">
                                    <p class="description" v-html="$t(`intro.eretailerDescription.${routeName}`)" ></p>
                                </div>

                            </div>
                        </transition>

                        <transition name="win-transition">
                            <div class="win" v-show="gameDone">
                                <BottleSprite />
                                <Header />

                                <div>
                                    <ButtonLink
                                        :label="$t('end.discover')"
                                        :link="discoverURL"
                                        :className="'full'"
                                        :target="'_blank'"
                                        v-on:click="buttonDiscoverClickHandler()"
                                        v-if="discoverURL"
                                    />
                                </div>

                                <div v-if="$te(`end.eretailer.${routeName}.text`)">
                                    <p class="description" v-html="$t(`end.eretailer.${routeName}.text`)"></p>
                                </div>
                                
                                <Button
                                    v-if="sampleActive"
                                    v-on:click="buttonSampleClickHandler()"
                                    :label="$t('end.sample')"
                                />
                                
                                <ButtonLink
                                    v-else-if="!sampleActive"
                                    :label="$te(`end.eretailer.${routeName}.cta`) ? $t(`end.eretailer.${routeName}.cta`) : $t('end.subscribe')"
                                    :link="subscribeURL"
                                    :target="'_blank'"
                                    :className="subscribeCTAClass ? subscribeCTAClass : ''"
                                    v-on:click="buttonSubscribeClickHandler()"
                                />
                                
                            </div>
                        </transition>

                    </div>
                </transition>

                <transition name="form-transition">
                    <div class="content-container" v-show="displayForm">
                        <span v-on:click="closeForm()" class="close-button"></span>
                        <RegisterForm v-if="!formURL" />
                        <EmbedForm v-if="formURL && gameDone" :url="formURL" />
                    </div>
                </transition>

            </div>

            <div class="diptych-item game-section" v-on:click="mainGameClickHandler()" :class="{'game-done' : gameDone}">
                <transition name="video-transition">
                    <VideoSplashScreen v-show="!gameStarted" />
                </transition>
                <JPGGame
                    ref="JPGGame"
                    :moveNumber="movesNumber"
                    v-on:threeLoadingCallback="threeLoadingCallback"
                    v-on:threeLoadedCallback="threeLoadedCallback"
                    v-on:endGameCallback="endGameCallback"
                    v-on:updatePointCallback="updatePointCallback"
                    v-on:endTuto="endTuto"
                    v-on:moveCounter="moveChange"
                    v-on:firstPoint="firstPoint"
                />
            </div>
        </div>
    </div>
</template>

<script>
import Header from "@/components/Header.vue"
import VideoSplashScreen from "@/components/VideoSplashScreen.vue"
import JPGGame from '@/components/Game.vue'
import GameScore from '@/components/game-components/GameScore.vue'
import ButtonStart from '@/components/ui/ButtonStart.vue'
import Button from '@/components/ui/Button.vue'
import TextBanner from '@/components/ui/TextBanner.vue'
import ButtonLink from '@/components/ui/ButtonLink.vue'
import BottleSprite from '@/components/ui/BottleSprite.vue'
import FinalScore from '@/components/FinalScore.vue'
import RegisterForm from '@/components/RegisterForm.vue'
import EmbedForm from '@/components/EmbedForm.vue'
import LangSwitcher from '@/components/LangSwitcher.vue'

export default {
    name: "Home",
    data() {
        return {
            gameStarted: false,
            gameDone: false,
            gameIsRunning: false,
            tutoEnded: false,
            displayForm: false,
            movesNumber: 12,
            movePlaying: null,
            points:0,
            multiplicateur:0,
            loadProgress: null,
            routeName: null,
            sampleActive: null,
            formURL: null,
            discoverURL: null,
            subscribeURL: null,
            subscribeCTAClass: null,
            langControl: false,
            enableGameTracking: false,
            progressFlag: [],
            gameClicked: false,
            audioControl: null,
            audioMuted: true
        }
    },
    components: {
        Header,
        ButtonStart,
        Button,
        VideoSplashScreen,
        TextBanner,
        JPGGame,
        GameScore,
        BottleSprite,
        FinalScore,
        ButtonLink,
        RegisterForm,
        EmbedForm,
        LangSwitcher
    },
    methods: {
        start() {
            this.$refs.JPGGame.startGame();
            this.gameStarted = !this.gameStarted
            this.gameIsRunning = true
            this.$audio.playAudio("background")
            this.audioMuted = false
        },
        reStartApp() {
            this.gameDone = false
            this.gameStarted = false
            this.tutoEnded = false
            this.gameIsRunning = false
            this.displayForm = false
            this.points = 0
            this.multiplicateur = 0
            this.progressFlag = []

            let interaction = {}
            interaction.campaign = {}
            interaction.campaign.name = "scandal pour homme - boxing the king of the ring"
            interaction.campaign.step = "step 3"
            interaction.campaign.clickCTA = "play again"


            if (this.taggingPlan && window.dataLayer) {
                window.dataLayer.push({
                    'event': 'campaignInteraction',
                    'interaction': interaction
                })
            }
        },
        muteAudio() {
            if (this.audioMuted) {
                this.$audio.unmuteAll()
                this.audioMuted = false
            } else {
                this.$audio.muteAll()
                this.audioMuted = true
            }
        },
        threeLoadingCallback(progress){
            this.loadProgress = progress
        },
        threeLoadedCallback(){
            console.log("loaded")
        },
        updatePointCallback(_points,_multi){
            this.points = _points
            this.multiplicateur = _multi
        },
        endGameCallback(points){
            this.points = points
            this.gameDone = true
            this.gameIsRunning = false
            this.enableGameTracking = false


            let interaction = {}
            interaction.campaign = {}
            interaction.campaign.name = "scandal pour homme - boxing the king of the ring"
            interaction.campaign.step = "step 3"


            if (this.taggingPlan && window.dataLayer) {
                window.dataLayer.push({
                    'event': 'campaignEnd',
                    'interaction': interaction
                })
            }
        },
        buttonStartClickHandler() {

            if (this.loadProgress < 100) {
                return;
            }
            
            let interaction = {}
            interaction.campaign = {}
            interaction.campaign.name = "scandal pour homme - boxing the king of the ring"
            interaction.campaign.step = "step 1"


            if (this.taggingPlan && window.dataLayer) {
                window.dataLayer.push({
                    'event': 'campaignStart',
                    'interaction': interaction
                })
            }

            this.start()
        },
        buttonSubscribeClickHandler() {
            let interaction = {}
            interaction.campaign = {}
            interaction.campaign.name = "scandal pour homme - boxing the king of the ring"
            interaction.campaign.step = "step 3"
            interaction.campaign.clickCTA = "subscribe to our latest news"


            if (this.taggingPlan && window.dataLayer) {
                window.dataLayer.push({
                    'event': 'campaignInteraction',
                    'interaction': interaction
                })
            }
        },
        buttonDiscoverClickHandler() {
            let interaction = {}
            interaction.campaign = {}
            interaction.campaign.name = "scandal pour homme - boxing the king of the ring"
            interaction.campaign.step = "step 3"
            interaction.campaign.clickCTA = "scandal pour homme - discover"


            if (this.taggingPlan && window.dataLayer) {
                window.dataLayer.push({
                    'event': 'campaignInteraction',
                    'interaction': interaction
                })
            }
        },
        buttonSampleClickHandler() {
            this.displayForm = true
        },
        closeForm() {
            this.displayForm = false
        },
        endTuto(){
            this.tutoEnded = true
            
            window.setTimeout( () => {
                this.enableGameTracking = true
            }, 100)
        },
        moveChange(_count){
            this.gameClicked = false
            let percentage = (_count * 100) / this.movesNumber
            this.movePlaying = _count

            if (percentage >= 25 && !this.progressFlag.includes(25)) {
                this.progressFlag.push(25)
                let interaction = {}
                interaction.campaign = {}
                interaction.campaign.name = "scandal pour homme - boxing the king of the ring"
                interaction.campaign.step = "step 2"
                interaction.campaign.progress = "25"


                if (this.taggingPlan && window.dataLayer) {
                    window.dataLayer.push({
                        'event': 'campaignProgress',
                        'interaction': interaction
                    })
                }
            }

            if (percentage >= 50 && !this.progressFlag.includes(50)) {
                this.progressFlag.push(50)
                let interaction = {}
                interaction.campaign = {}
                interaction.campaign.name = "scandal pour homme - boxing the king of the ring"
                interaction.campaign.step = "step 2"
                interaction.campaign.progress = "50"


                if (this.taggingPlan && window.dataLayer) {
                    window.dataLayer.push({
                        'event': 'campaignProgress',
                        'interaction': interaction
                    })
                }
            }

            if (percentage >= 75 && !this.progressFlag.includes(75)) {
                this.progressFlag.push(75)
                let interaction = {}
                interaction.campaign = {}
                interaction.campaign.name = "scandal pour homme - boxing the king of the ring"
                interaction.campaign.step = "step 2"
                interaction.campaign.progress = "75"


                if (this.taggingPlan && window.dataLayer) {
                    window.dataLayer.push({
                        'event': 'campaignProgress',
                        'interaction': interaction
                    })
                }
            }
        },
        firstPoint(){
            let interaction = {}
            interaction.campaign = {}
            interaction.campaign.name = "scandal pour homme - boxing the king of the ring"
            interaction.campaign.step = "step 2"
            interaction.campaign.clickCTA = `screen ${this.movePlaying} - boxing move`

            this.gameClicked = true

            if (this.taggingPlan && window.dataLayer) {
                window.dataLayer.push({
                    'event': 'campaignInteraction',
                    'interaction': interaction
                })
            }
        },
        mainGameClickHandler() {
            if (this.enableGameTracking) {

                if (!this.gameClicked) {
                    let interaction = {}
                    interaction.campaign = {}
                    interaction.campaign.name = "scandal pour homme - boxing the king of the ring"
                    interaction.campaign.step = "step 2"
                    interaction.campaign.clickCTA = `screen ${this.movePlaying} - boxing move`

                    if (this.taggingPlan && window.dataLayer) {
                        window.dataLayer.push({
                            'event': 'campaignInteraction',
                            'interaction': interaction
                        })
                    }
                    this.gameClicked = true
                }
                
            }
        
        }
    },
    mounted() {
        // I set the value to true at the beginning to trick the browser into thinking
        // it needs to download the sprite used in <BottleSprite>
        // In order to have a smooth transition after game is done
        this.gameDone = false

        // Addaptation configuration
        this.routeName = this.$route.name
        this.sampleActive = this.$route.meta.sample
        this.formURL = this.$route.meta.formURL

        if (this.$i18n.locale === 'it' || this.$i18n.locale === 'br' || this.$i18n.locale === 'euit') {
            this.discoverURL = this.$t('end.discoverURL')
        } else {
            this.discoverURL = this.$route.meta.discoverURL
        }

        this.subscribeURL = this.$route.meta.subscribeURL
        this.subscribeCTAClass = this.$route.meta.subscribeCTAClass
        this.langControl = this.$route.meta.langControl
        this.taggingPlan = this.$route.meta.taggingPlan
        this.audioControl = this.$route.meta.audioControl

    },
};
</script>

<style lang="scss">

.home {
    width: 100%;
    height: 100%;
}

.diptych-container {
    display: flex;
    align-items: stretch;
    height: 100%;

    .diptych-item {
        position: relative;
        width: 50%;
        height: 100%;

        &.content-section {
            background: linear-gradient(180deg, #192935 0%, #121C25 100%);

            .header {
                margin-top: 50px;
            }

            .background-image {
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
                object-fit: cover;
                opacity: 0;

                transition: all 1000ms $easeInSine;

                &.show {
                    opacity: 1;
                }
            }

            .content-container {
                position: relative;
                height: 100%;
                z-index: 5;

                .close-button {
                    position: absolute;
                    z-index: 999;
                    top: 25px;
                    left: 25px;
                    width: 30px;
                    height: 30px;
                    display: block;

                    &::after, &::before {
                        content: '';
                        position: absolute;
                        top: 14px;
                        left: 0;
                        width: 100%;
                        height: 2px;
                        background-color: #ffffff;
                    }

                    &::after {
                        transform: rotate(45deg);
                    }

                    &::before {
                        transform: rotate(-45deg);
                    }
                }

                &.description-eretailer {
                    & > .header .logo-scandal {
                        max-width: 200px;
                    }

                    .header .logo-jpg {
                        max-width: 100px;
                    }

                    .intro .topline {
                        font-size: 16px;
                    }

                    .intro .crown {
                        width: 10vh;
                    }

                    .intro .main-title {
                        width: 24vh;
                    }
                }
            }
        }
    }

}

.mute-icon {
    position: fixed;
    z-index: 999;
    width: 18px;
    bottom: 25px;
    right: 25px;
    cursor: pointer;

    svg {
        width: 100%;
    }

    &::after {
        content: '';
        display: none;
        position: absolute;
        top: 0;
        left: 45%;
        width: 30px;
        height: 1px;
        background-color: $color-white;
        transform: rotate(-50deg) translate(-50%, -50%);
    }

    &.muted::after {
        display: block;
    }
}

.intro {
    margin: 15px auto 35px auto;

    .topline {
        color: $color-sorrel-brown;
        font-size: 20px;
        line-height: normal;
        text-transform: uppercase;
        margin-bottom: 40px;
    }

    .crown {
        max-width: 115px;
        width: 12vh;
        margin: 0 auto 30px auto;
    }

    .main-title {
        width: 30vh;
        max-width: 280px;
        margin: 0 auto 35px auto;
    }

    .description-container {
        margin-top: 30px;
        padding: 0 80px;
    }

    .description {
        font-size: 24px;
        line-height: 30px;
        font-weight: 500;
        text-transform: uppercase;
        color: $color-sorrel-brown;
    }

    &.fr, &.be {

        .crown {
            width: 10vh;
            margin-bottom: 20px;
        }

        .main-title {
            width: 24vh;
        }
    }

    &.it, &.euit {
        .main-title {
            width: 23vh;
        }
    }

}

.diptych-container .diptych-item.content-section .win .header {
    margin-top: -70px;
}

.win {

    .header {
        margin-bottom: 50px;
    }

    .button-link.full {
        margin: 0 auto 25px auto;
    }
}

// Video transition
.video-transition-enter-active {
    transition: all 800ms $easeOutCubic;
}
.video-transition-leave-active {
    transition: all 650ms $magikMotion 1000ms;
}
.video-transition-enter, .video-transition-leave-to {
    transform: translateX(100%);
}

// Intro transition
.intro-transition-enter-active {
    transition: all 800ms $authenticMotion;
}
.intro-transition-leave-active {
    transition: all 650ms $easeInCubic;
}
.intro-transition-enter, .intro-transition-leave-to {
    opacity: 0;
    transform: translateY(-100%);
}

// Content transition
.content-transition-enter-active {
    transition: all 800ms $easeOutCubic 900ms;
    position: absolute!important;
    left: 0!important;
    top: 0!important;
    width: 100%!important;
}
.content-transition-leave-active {
    transition: all 800ms $easeInCubic;
}
.content-transition-enter {
    transform: translateY(-100%);
}

.content-transition-leave-to {
    transform: translateY(-100%);
}

.form-transition-enter-active {
    transition: all 800ms $easeOutCubic 900ms;
}
.form-transition-leave-active {
    transition: all 800ms $easeInCubic;
}
.form-transition-enter {
    transform: translateY(100%);
}

.form-transition-leave-to {
    position: fixed;
    top: 0;
    left: 0;
    transform: translateY(100%);
}


// Game score transition
.game-score-transition-enter-active {
    transition: all 750ms $easeOutCubic 500ms;
}
.game-score-transition-leave-active {
    transition: all 800ms $easeInOutSine;
}
.game-score-transition-enter {
    top: 100%;
    opacity: 0;
}

.game-score-transition-leave-to {
    opacity: 0;
}

// Game score transition
.text-banner-transition-enter-active {
    transition: all 750ms $easeOutCubic 1000ms;
}
.text-banner-transition-leave-active {
    transition: all 650ms $easeInCubic;
}
.text-banner-transition-enter, .text-banner-transition-leave-to {
    opacity: 0;
}

// Game score transition
.final-score-transition-enter-active {
    transition: all 750ms $easeOutCubic 1000ms;
}
.final-score-transition-leave-active {
    transition: all 650ms $easeInCubic;
}
.final-score-transition-enter, .final-score-transition-leave-to {
    opacity: 0;
    transform: translateY(-200px);
}

// Game score transition
.win-transition-enter-active {
  transition: all 800ms $easeOutCubic 2500ms;
}
.win-transition-leave-active {
  transition: all 650ms $easeInCubic;
}
.win-transition-enter, .win-transition-leave-to {
    opacity: 0;
    transform: translateY(100%);
}


@media screen and (max-height: 800px) and (min-width: 768px) {
    .intro .main-title {
        margin-bottom: 30px;
    }

    .diptych-container .diptych-item.content-section .header {
        margin-top: 30px;
    }

    .diptych-container .diptych-item.content-section .win .header {
        margin-top: -110px;
    }

    .win .button-link.full {
        margin: 30px auto 25px auto;
    }
    .intro .topline {
        display: none;
    }

}

@media screen and (max-height: 700px) and (min-width: 768px) {
    .intro .main-title {
        margin-bottom: 30px;
    }


}

// Ipad pro vertical
@media screen and (max-width: 1024px) and (orientation: portrait) {
    .diptych-container {
        display: block;

        .diptych-item {
            width: 100%;

            &.content-section {
                padding-top: 50px;

                .header {
                    margin-top: 0;
                }
            }

            &.game-section {
                transform: translateY(-100%);
            }

            &.game-done.game-section {
                transform: translateY(0);
            }
        }
    }
}


// Mobile 768px

@include max-width(s) {
    .diptych-container {
        display: block;

        .diptych-item {
            width: 100%;

            &.content-section {
                padding-top: 30px;

                .header {
                    margin-top: 0;
                }
            }

            &.game-section {
                transform: translateY(-100%);
            }

            &.game-done.game-section {
                transform: translateY(0);
            }
        }
    }

    .mute-icon {
        position: fixed;
        z-index: 999;
        width: 13px;
        bottom: 15px;
        right: 15px;
        cursor: pointer;

        svg {
            width: 100%;
        }

        &::after {
            content: '';
            display: none;
            position: absolute;
            top: 0;
            left: 45%;
            width: 23px;
            height: 1px;
            background-color: $color-white;
            transform: rotate(-50deg) translate(-50%, -50%);
        }

        &.muted::after {
            display: block;
        }
    }


    .intro {
        margin: 25px auto;

        .topline {
            display: none;
        }

        .crown {
            max-width: 80px;
            margin: 0 auto 20px auto;
        }

        .main-title {
            max-width: 230px;
            margin: 0 auto 30px auto;
        }

    }

    .win {
        .header {
            margin-bottom: 30px;
        }
    }

    .diptych-container .diptych-item.content-section .win .header {
        margin-top: -40px;
    }

    // Video transition
    .video-transition-enter-active {
        transition: all 800ms $easeOutCubic;
    }
    .video-transition-leave-active {
        transition: all 800ms $easeInCubic 1000ms;
    }
    .video-transition-enter, .video-transition-leave-to {
        transform: translateY(-100%);
    }
}

@include max-width(xxs) {
    .intro .main-title {
        margin: 0 auto 45px auto;
    }

    .diptych-container .diptych-item.content-section .win .header {
        margin-top: -60px;
    }
}
</style>
