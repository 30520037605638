<template>
  <div class="game">
    <gameCountdown v-if="state=='countdown'" v-on:endCountdown="beginGame"/>
    <gameCallback class="callback" v-bind:points="lastPointEarn" v-bind:speed="speed"></gameCallback>
    <div class="clickZone">
        <gamePoints ref="pointsDiv"
          v-for="(item,index) in pointsArray" 
          :key="index"
          v-bind:idPoint="index"
          v-bind:datas="item"
          v-bind:indStart="moveCounter"
          v-on:points="clickedPoints"
          v-on:loosePoint="loosePoints"
        ></gamePoints>
    </div>
    <gameThree 
      ref="threejs"
      v-bind:currentAction="selectedMove" 
      v-bind:speed="speed"
      v-on:threeLoadingCallback="$emit('threeLoadingCallback',$event)"
      v-on:threeLoadedCallback="$emit('threeLoadedCallback')"
    ></gameThree>
    <gameTuto v-if="state=='tuto'" v-on:endTuto="startCountdown"/>
    <div v-if="state=='end'" class="overlay"></div>
  </div>
</template>


<script>
import moves from '../assets/game/json/moves.json'

import gamePoints from './game-components/GamePoints.vue'
import gameThree from './game-components/GameThree.vue'
import gameCallback from './game-components/GameTextCallback.vue'
import gameCountdown from './game-components/GameCountdown.vue'
import gameTuto from './game-components/GameTuto.vue'

export default {
  name: 'JPGGame',
  components: {
    gamePoints,
    gameThree,
    gameCallback,
    gameCountdown,
    gameTuto
  },
  props: {
    moveNumber:Number
  },
  data: function () {
  return {
    movesJson : moves,
    pointsArray : [],
    state:"idle",
    selectedMove : null,
    moveCounter: 0,
    clickedArray : 0,
    speed : 1.5,
    score : 0,
    coup : 0,
    multiplicateur: 0,
    lastPointEarn:null,
    isTouch : false,
    firstPoint: false
  }
},
  methods: {
      updatePoints:function(key){
        //Set les points clickable
        this.pointsArray = []

        for(let i=0;i<5;i++){
          if(i< this.movesJson[key].points.length){
            const location = this.movesJson[key].points[i].position;
            const pos = location;
            const time = (this.speed * this.movesJson[key].timingMove)*this.movesJson[key].points[i].timing;
            let duration = 0;
            if(i+1<this.movesJson[key].points.length){
              duration = ((this.speed * this.movesJson[key].timingMove)*this.movesJson[key].points[i+1].timing) - time;
            }else{
               duration = (this.speed * this.movesJson[key].timingMove) - time;
            }
            this.pointsArray[i]={
              position : pos,
              show : true ,
              number : this.movesJson[key].points[i].text,
              time : time,
              duration : duration
            }
          }else{
            this.pointsArray[i]={
              position:[],
              show:false,
              number:""
            }
          }
        }
      },
      startPhase:function(){
        //Choose the next move

          const keys = Object.keys(this.movesJson);
          let self = this;
          const filtered = keys.filter(function(value){ 
              return value != self.selectedMove;
          });
          const r = rand(0,filtered.length-1);
          //Set the points
          this.updatePoints(filtered[r])

          this.coup = 0;
          this.selectedMove = null
          this.firstPoint=false;
          //Pause before the next move
          setTimeout(()=>{
            //Start next move
            this.moveCounter++;
            this.$emit('moveCounter',this.moveCounter)
            this.selectedMove = filtered[r];
            //Timeout End Move
            setTimeout(this.endPhase,250+(this.speed * this.movesJson[keys[r]].timingMove)*1000)
          },1000*this.speed)
      },
      endPhase:function(){
          //Test if game is finished
          if(this.moveCounter >= this.moveNumber){
            //If true end game & send score
            this.$emit('endGameCallback',this.score)
            this.selectedMove="Victory"
            this.state="end";
            this.$audio.playAudio("transition")
            this.pointsArray = []
          }else{
            //If not reset
            this.coup = 0;
            //Pause before starting new phase
              setTimeout(this.startPhase,1000*this.speed)
          }
          //Add speed
          let limitSpeed =0.95;
          if(this.isTouch){
            limitSpeed = 0.85;
          }
          if(this.speed>limitSpeed){
            this.speed -= 0.05;
          }
          
      },
      clickedPoints:function(p){
        //Multiply score by multiplier
        this.score+=(p*(this.multiplicateur+1));
        //Increment hit counter
        this.coup++;
        //If hit counter == move points length && multi less that 5
        if(this.coup == this.movesJson[this.selectedMove].points.length && this.multiplicateur<5){
          //true increment multiplicateur
          this.multiplicateur++;
          //Send combot message to callbacktext
          // this.lastPointEarn = 110
          this.lastPointEarn =-1;
          setTimeout(()=>{
            this.lastPointEarn = 110;
          },20)
          this.$audio.playAudio("combot")
        }else{
          //Send score to callbacktext
          //this.lastPointEarn = p
          this.lastPointEarn =-1;
          setTimeout(()=>{
            this.lastPointEarn = p;
            
          },20)
          if(p>50){
            this.$audio.playAudio("excellent")
          }else{
            this.$audio.playAudio("greater")
          }
        }
        this.$refs.threejs.shakeGame()
        //Send point et multiplicateur to parent
        this.$emit('updatePointCallback',this.score,this.multiplicateur)
        if(!this.firstPoint){
          this.$emit('firstPoint')
          this.firstPoint=true;
        }
      },
      loosePoints:function(){
        //If missclick
        this.multiplicateur = 0;
        //Send missclick to callbackText
        this.lastPointEarn =-1;
        setTimeout(()=>{
          this.lastPointEarn = 0;
        },20)
         
         //Send point et multiplicateur to parent
        this.$emit('updatePointCallback',this.score,this.multiplicateur)
      },
      //Start the Game
      startGame(){

       this.state="idle"; 
       this.multiplicateur = 0;
       this.score = 0;
       if(this.isTouch){
         this.speed = 1.2;
       }else{
         this.speed = 1.4;
       }
       this.moveCounter=0;
       this.$emit('moveCounter',this.moveCounter)
       this.selectedMove="Idle"
       this.$emit('updatePointCallback',this.score,this.multiplicateur)
       setTimeout(()=>{
         this.state="tuto"; 
       },100)
        // this.beginGame()
      },
      startCountdown(){
        this.setState('countdown')
        this.$emit('endTuto')
        this.$audio.playAudio("decompte")
      },
      //Start the parti
      beginGame(){
        this.state="game";
        this.startPhase()
      },
      setState(s){
        this.state = s;
      }

  },
  mounted:function(){
    window.addEventListener('touchstart', ()=> {
      this.isTouch = true;
    });
  }
}
function rand(min, max){
    return (Math.floor(Math.pow(10,14)*Math.random()*Math.random())%(max-min+1))+min;
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
  .game{
    width : 100%;
    height: 100%;
    background : black;
    position: relative;
  }
  .callback{
    position: relative;
    z-index: 4;
    height: 20%;
    padding-top: 50px;
    max-width: 700px;
    margin: auto;
    width: 90%;
}

  .clickZone{
    width: 100%;
    height: 80%;
    position: relative;
    z-index: 10;
  }
  
  h4{
    color:white;
    position: relative;
    z-index: 20;
  }
  .overlay{
    position: absolute;
    width: 100%;
    height : 100%;
    z-index: 0;
    top : 0;
    background: rgba(18,28,37,0.6);
  }
  /*Transition*/
  .fade-enter-active, .fade-leave-active {
  transition: opacity .5s;
  }
  .fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
    opacity: 0;
  }

  
  @media screen and  (max-width: 768px) {
    .callback{
      height: 30%;
      padding-top: 90px;
      width:80%;
    }

    .clickZone{
      width: 90%;
      height: 57%;
    }
  }
</style>
