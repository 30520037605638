<template>
    <div class="lang-hub">
        <div class="lang-list">
            <div class="lang-item" v-for="locale in locales" :key="locale" @click="onClickHandler(locale)">
                <span>
                    {{locale}}
                </span>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        props: {
            locales: Array
        },
        methods: {
            onClickHandler(locale) {
                this.$router.push({
                    params: {locale : locale}
                })

                this.$emit('localeChosen')
            }
        }
    }
</script>

<style lang="scss">
    .lang-hub {
        position: relative;

        .lang-list {
            display: flex;
            justify-content: space-between;
            align-items: center;

            .lang-item {
                position: relative;
                width: 60px;
                height: 60px;
                border-radius: 50%;
                border: 1px solid $color-sorrel-brown;
                cursor: pointer;

                span {
                    position: absolute;
                    top: 50%;
                    left: 50%;
                    transform: translate(-50%, -50%);
                    text-transform: uppercase;
                    color: $color-sorrel-brown;
                }
            }
        }
    }
</style>