<template>
    <div class="embed-form-container">
        <iframe :src="url" frameborder="0" width="100%"></iframe>
    </div>
</template>

<script>

export default {
    name: "EmbedForm",
    props: {
        url: String
    }
};
</script>

<style lang="scss">

.embed-form-container {
    font-family: "Courier";
    font-weight: normal;
    color: $color-sorrel-brown;
    text-align: left;
    margin: 0 auto;
    width: 100%;
    height: 100%;

    iframe {
        width: 100%;
        height: 100%;
    }
}

</style>
