<template>
    <div v-on:click="$emit('click')" class="button noselect" :class="className">
        <span>
            {{ label }}
        </span>
    </div>
</template>

<script>
export default {
    name: "Button",
    props: {
        label: String,
        className: String
    },
};
</script>

<style lang="scss">
.button {
    position: relative;
    display: inline-block;

    font-size: 18px;
    line-height: 22px;
    letter-spacing: -0.04em;
    text-transform: uppercase;
    color: $color-sorrel-brown;
    cursor: pointer;

    &.full {
        position: relative;
        display: inline-block;
        min-width: 160px;
        padding: 0 25px;
        border: 1px solid $color-sorrel-brown;
        background-color: $color-sorrel-brown;
        font-family: "Druk", Helvetica, Arial, sans-serif;
        text-align: center;
        text-transform: uppercase;
        font-size: 17px;
        letter-spacing: -0.02em;
        color: $color-black;
        line-height: 50px;

        overflow: hidden;
        cursor: pointer;

        &:hover a {
            color: $color-white;
        }
    }

    &::after {
        content: '';
        position: absolute;
        left: 0;
        bottom: 0;
        width: 100%;
        height: 2px;
        background-color: $color-sorrel-brown;
    }
}
</style>
