export default {
    en: "EN",
    fr: "FR",
    es: "ES",
    de: "DE",
    pt: "PT",
    nl: "NL",
    be: "BE",
    it: "IT",
    br: "BR",
    euit: "EU-IT",
}