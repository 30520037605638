<template>
    <div class="form-container">
        <form v-on:submit.prevent="onSubmit()" autocomplete="off">
            <div class="field-container select-field">
                <select v-model="formData.gender" v-bind:class="{hasvalue : formData.gender}" name="gender" id="gender">
                    <option value="F">{{$t('form.female')}}</option>
                    <option value="M">{{$t('form.male')}}</option>
                    <option value="O">{{$t('form.other')}}</option>
                </select>
                <label class="label" for="gender">{{$t('form.labels.gender')}}</label>
            </div>

            <div class="field-container">
                <input v-model="formData.firstname" v-bind:class="{hasvalue : formData.firstname}" type="text" name="firstname" id="firstname" autocomplete="new-password">
                <label class="label" for="firstname">{{$t('form.labels.firstname')}}</label>
            </div>

            <div class="field-container">
                <input v-model="formData.lastname" v-bind:class="{hasvalue : formData.lastname}" type="text" name="lastname" id="lastname" autocomplete="new-password">
                <label class="label" for="lastname">{{$t('form.labels.lastname')}}</label>
            </div>

            <div class="field-container">
                <input v-model="formData.email" v-bind:class="{hasvalue : formData.email}" type="email" name="email" id="email" autocomplete="new-password">
                <label class="label" for="email">{{$t('form.labels.email')}}</label>
            </div>

            <div class="field-container select-field">
                <select v-model="formData.country" v-bind:class="{hasvalue : formData.country}" name="country" id="country">
                    <option v-for="country in countries" :key="country.code" :value="country.code">
                        {{ country.name }}
                    </option>
                </select>
                <label class="label" for="country">{{$t('form.labels.country')}}</label>
            </div>

            <div class="checkboxes-container">
                <div class="field-container checkboxe-field">
                    <input v-model="formData.legals" id="legals" class="checkbox" type="checkbox" name="legals" value="1" />
                    <label for="legals">
                        {{$t('form.legals')}}
                    </label>
                </div>
                <div class="field-container checkboxe-field">
                    <input v-model="formData.optin" id="optin" class="checkbox" type="checkbox" name="optin" value="1" />
                    <label for="optin">
                        {{$t('form.optinNewsletter')}}
                    </label>
                </div>
            </div>

            <button type="submit" class="button full" value="Submit" v-on:click="onSubmit()">
                {{$t('form.submit')}}
            </button>
        </form>
    </div>
</template>

<script>
import countries from '@/data/countries.json'

export default {
    name: "RegisterForm",
    data() {
        return {
            formData: {
                gender: '',
                firstname: '',
                lastname: '',
                email: '',
                country: '',
                legals: false,
                optin: false
            },
            countries: countries
        }
    },
    methods: {
        onSubmit() {
            console.log(this.formData);
        }
    }
};
</script>

<style lang="scss">

.form-container {
    font-family: "Courier";
    font-weight: normal;
    color: $color-sorrel-brown;
    text-align: left;
    max-width: 350px;
    margin: 350px auto 0 auto;

    .field-container {
        position: relative;
        margin: 24px 0;
        
        &::after {
            content: '';
            position: absolute;
            left: 0;
            bottom: -2px;
            width: 100%;
            height: 1px;
            background-color: $color-sorrel-brown;
            display: block;
        }

        &.checkboxe-field::after {
            display: none;
        }
    }

    input, select {
        font-family: "Courier";
        font-weight: 100;
        color: $color-sorrel-brown;
        appearance: none;
        background: none;
        border: none;
        width: 100%;
        height: 25px;
        font-size: 14px;
        line-height: 25px;
        outline: none;
    }

    .select-field::before {
        content: '';
        display: block;
        position: absolute;
        bottom: 9px;
        right: 5px;
        width: 7px;
        height: 7px;
        border: 1px solid $color-sorrel-brown;
        border-top-color: transparent;
        border-right-color: transparent;

        transform: rotate(-45deg);
    }

    select:focus + label {
        top: -18px;
        font-size: 10px;
        opacity: 0.5;
    }

    select.hasvalue + label {
        top: -18px;
        font-size: 10px;
        opacity: 0.5;
    }

    input:focus + label {
        top: -18px;
        font-size: 10px;
        opacity: 0.5;
    }

    input.hasvalue + label {
        top: -18px;
        font-size: 10px;
        opacity: 0.5;
    }


    .label {
        position: absolute;
        left: 2px;
        top: 0;
        font-size: 14px;
        line-height: 25px;
        transition: all 250ms $magikMotion;
    }

    .checkboxes-container {
        margin: 40px 0 40px 0;
    }
}

input.checkbox{
    position: absolute;
    top:0;
    left:0;
    width: 0;
    height: 0;
    visibility: hidden;

    &.error +label {
        color: $color-white;
        &:before {
            border-color: $color-white;
        }

        button {
            color: $color-white;
        }
    }
}

input.checkbox +label {
    position: relative;
    padding-left: 30px;
    padding-top: 0;
    display: inline-block;
    margin: 0 auto;
    max-width: 900px;
    font-size: 12px;
    line-height: 16px;
    color: $color-sorrel-brown;
    text-align: left;

    button {
        display: inline;
        font-size: 12px;
        line-height: 16px;
        text-decoration: underline;
        color: $color-sorrel-brown;
    }

    &:before {
        content:'';
        position: absolute;
        top: 0;
        left: 0;
        display: block;
        width:15px;
        height:15px;
        border:1px solid $color-sorrel-brown;
        border-radius: 4px;
    }
}

input.checkbox +label a {
    text-decoration: none;
    position: relative;
    color: $color-white;

    &::after {
        content: '';
        position: absolute;
        left: 0;
        bottom: 0;
        width: 100%;
        height: 1px;
        background-color: $color-white;
    }

}

input.checkbox:checked +label {
    &:after {
        content: '';
        position: absolute;
        left: 4px;
        top: 5px;
        width: 8px;
        height: 4px;
        border-left: 1px solid $color-sorrel-brown;
        border-bottom: 1px solid $color-sorrel-brown;
        transform: rotate(-55deg);

    }
}

input.checkbox.error:checked +label {
    color: $color-white;

    &:before {
        border-color: $color-white;
    }
}

input.checkbox.error:checked +label a {
    color: $color-white;
    &::after {
        background-color: $color-white;
    }
}

input.checkbox.error + label {
    color: $color-guardsman-red;

    &:before {
        border-color: $color-guardsman-red;
    }
}

input.checkbox.error + label a {
    color: $color-guardsman-red;

    &::after {
        background-color: $color-guardsman-red;
    }
}


@media screen and (max-height: 800px) and (min-width: 768px) {
    .form-container {
        margin: 270px auto 0 auto;
    }
}

@include max-width(s) {
    .form-container {
        margin: 180px auto 0 auto;
        width: 100%;
        padding: 0 20px 50px;
    }
}

</style>
