<template>
    <div class="text-banner">
        <div ref="wrapper" class="text-banner-wrapper">
            <span v-for="(n, index) in multiplier" :key="index">
                {{title}}
            </span>
        </div>
    </div>
</template>

<script>
import {isPartlyInViewportX} from "@/utils/functions.js"
import anime from "animejs"

export default {
    name: "TextBanner",
    data() {
        return {
            multiplier: 10
        }
    },
    props: {
        title: String
    },
    methods: {
        autoScroll() {
            this.firstSpan = this.$refs.wrapper.querySelector('span:first-of-type');

            let vw = (window.innerWidth || document.documentElement);
            let textWidth = this.firstSpan.offsetWidth;

            this.autoTranslateAnime = anime({
                targets: this.$refs.wrapper,
                translateX: '-' + (textWidth > vw ? textWidth : vw),
                duration: 20000,
                easing: 'linear',
                loop: true,
                update: () => {
                    if (!isPartlyInViewportX(this.firstSpan)) {
                        this.autoTranslateAnime.restart();
                    }
                }
            });
        }
    },
    mounted() {
        this.autoScroll()
    }
}
</script>

<style scope lang="scss">
.text-banner {
    position: fixed;
    z-index: 1;
    left: 0;
    right: 0;
    top: 115px;
    width: 100%;
    overflow: hidden;
    margin: 0;
    padding: 0;

    .text-banner-wrapper {
        width: auto;
        white-space: nowrap;
    }

    span {
        color: $color-sorrel-brown;
        -webkit-text-fill-color: transparent; /* Will override color (regardless of order) */
        -webkit-text-stroke-width: 2px;
        -webkit-text-stroke-color: $color-sorrel-brown;
        font-size: 200px;
        line-height: 200px;
        text-transform: uppercase;
    }
}

@media screen and (max-height: 800px) and (min-width: 768px) {

    .text-banner {
        top: 75px;
    }

    .text-banner span {
        font-size: 150px;
        line-height: 150px;
    }
}

@include max-width(s) {
    .text-banner {
        top: 55px;
    }


    .text-banner span {
        font-size: 140px;
        line-height: 140px;
    }
}

</style>