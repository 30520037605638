<template>
  <div class="callback-text" >
      <div class="onomatope" :style="{flex:flex[0],order:order}">
          <onomatope
            :style="styleOnomatope"
            v-bind:text="onomatope"
            v-bind:size="onoSize" 
            v-bind:duration="0.50*speed"
            v-bind:color="'white'"  
          />
      </div>
      <div class="result"  :style="{flex:flex[1]}" >
        <textFadeScale class="result-text" 
        :style="styleResult"
        v-bind:text="callback" 
        v-bind:size="callbackSize" 
        v-bind:duration="0.60*speed" 
        v-bind:color="colorResult"
        v-bind:animType="resultType"
        />
      </div>
  </div>
</template>


<script>
import textFadeScale from './TextFadeScale.vue'
import callbackText from '../../assets/game/json/gameCallback.json'
import onomatope from './Onomatope.vue'
export default {
    name: 'GameTextCallback',
    components: {
        textFadeScale,
        onomatope
    },
  props: {
      points:Number,
      speed:Number
  },
    data:()=>{
    return{
        callback:"",
        onomatope:"",
        show:false,
        colorResult:'',
        resultType:'',
        styleResult:{},
        styleOnomatope:{},
        flex:[1,1],
        order:0,
        onoSize:10,
        callbackSize : 20
    }
  },
  methods: {
    setText(p){
        if(p==-1){
            this.callback = "";
        }else{
            if(p==0){
                this.resultType = 'animFade'
                this.colorResult = '#C5000D'
            }else{
                this.resultType = 'animScale'
                this.colorResult = '#C9B68F'
                this.onomatope = ''
                setTimeout(()=>{
                    this.onomatope = callbackText.onomatope[Math.floor(Math.random()*callbackText.onomatope.length)]
                },20)  
            }

            if(p==110){
                this.callback = callbackText.combot;
                this.show = true;
            }else{
                for(let i=0;i<callbackText.callback.length;i++){
                    if(p>=callbackText.callback[i].min && p<=callbackText.callback[i].max){
                        this.callback = callbackText.callback[i].text
                            break;
                    }
                }
                this.show = true;
            }
            this.setTransform();
        }
    },
    setTransform(){
        this.styleResult.transform = 'rotate('+getRandomArbitrary(-30,30)+'deg) translate('+getRandomArbitrary(-20,20)+'px,'+getRandomArbitrary(-20,20)+'px)';
        this.styleOnomatope.transform = 'rotate('+getRandomArbitrary(-30,30)+'deg) translate('+getRandomArbitrary(-20,20)+'px,'+getRandomArbitrary(-20,20)+'px) scale('+getRandomArbitrary(1,1.2)+')';
        this.order = Math.floor(getRandomArbitrary(0,2))
        this.flex = [Math.floor(getRandomArbitrary(1,4)),Math.floor(getRandomArbitrary(1,4))]
    },
    setFontSize(){
        if (window.matchMedia("(max-width: 768px)").matches) {
            this.onoSize =32
            this.callbackSize = 42
        } else {
            this.onoSize =40
            this.callbackSize = 50
        }
    }

  },
  watch: {
    points: function (p) {
        this.setText(p);   
    }
  },  
  mounted:function(){
      this.setFontSize();
      window.addEventListener('resize',this.setFontSize)
  }
}

function getRandomArbitrary(min, max) {
  return Math.random() * (max - min) + min;
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

.result.actif{
    transform:scale(1);
}
.callback-text{
    display: flex;
}
.result-text{
    width:100%;
    height: 100%;
}
.onomatope{
    flex:1;
    height: 100%;
    position: relative;
}
.result{
    flex: 3;
    height: 100%;
    position: relative;
}
</style>
