<template>
    <div class="sprite-container">
        <img v-if="stop" class="static-frame" src="../../assets/flacon_000.png" alt="">
        <div class="sprite flacon_0" ref="sprite"></div>
    </div>
</template>

<script>
export default {
    name: "BottleSprite",
    data() {
        return {
            index: 1,
            stop: true
        }
    },
    methods: {
        startSprite(fps) {
            this.fpsInterval = 1000 / fps
            this.then = Date.now()
            this.startTime = this.then
            this.animateSprite()
        },
        animateSprite() {

            requestAnimationFrame(this.animateSprite)

            if (this.stop) {
                return
            }

            this.now = Date.now()
            this.elapsed = this.now - this.then

            if (this.elapsed > this.fpsInterval) {
                this.then = this.now - (this.elapsed % this.fpsInterval)

                if (this.index >= 181) {
                    this.$refs.sprite.classList.remove('flacon_180')
                    this.index = 0
                }
                
                this.$refs.sprite.classList.remove('flacon_' + (this.index - 1))
                this.$refs.sprite.classList.add('flacon_' + this.index)
                this.index++
                
            }
        },
    },
    beforeMount() {
        this.startSprite(45)
    },
    beforeDestroy() {
        this.stop = true
    },
    mounted() {
        window.setTimeout( () => {
            this.stop = false
        }, 2500)
    },
};
</script>

<style lang="scss">

$w: 540px;

.sprite-container {
    position: relative;
    z-index: 999;
    width: $w;
    height: $w;
    margin: auto;
    transform: scale(0.75);
}

.static-frame {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
}

.sprite {
    display: block;
    width: $w;
    height: $w;
    overflow:hidden;
    background-repeat: no-repeat;
    // Need to preload that at start, see Home.vue mounted state
    background-image:url('../../assets/scandal-flacon-sprite.png');
}

.flacon_0 {width:$w; height:$w; background-position: -1px -1px}
.flacon_1 {width:$w; height:$w; background-position: -543px -1px}
.flacon_2 {width:$w; height:$w; background-position: -1085px -1px}
.flacon_3 {width:$w; height:$w; background-position: -1627px -1px}
.flacon_4 {width:$w; height:$w; background-position: -2169px -1px}
.flacon_5 {width:$w; height:$w; background-position: -2711px -1px}
.flacon_6 {width:$w; height:$w; background-position: -3253px -1px}
.flacon_7 {width:$w; height:$w; background-position: -3795px -1px}
.flacon_8 {width:$w; height:$w; background-position: -4337px -1px}
.flacon_9 {width:$w; height:$w; background-position: -4879px -1px}
.flacon_10 {width:$w; height:$w; background-position: -5421px -1px}
.flacon_11 {width:$w; height:$w; background-position: -5963px -1px}
.flacon_12 {width:$w; height:$w; background-position: -6505px -1px}
.flacon_13 {width:$w; height:$w; background-position: -7047px -1px}
.flacon_14 {width:$w; height:$w; background-position: -1px -543px}
.flacon_15 {width:$w; height:$w; background-position: -543px -543px}
.flacon_16 {width:$w; height:$w; background-position: -1085px -543px}
.flacon_17 {width:$w; height:$w; background-position: -1627px -543px}
.flacon_18 {width:$w; height:$w; background-position: -2169px -543px}
.flacon_19 {width:$w; height:$w; background-position: -2711px -543px}
.flacon_20 {width:$w; height:$w; background-position: -3253px -543px}
.flacon_21 {width:$w; height:$w; background-position: -3795px -543px}
.flacon_22 {width:$w; height:$w; background-position: -4337px -543px}
.flacon_23 {width:$w; height:$w; background-position: -4879px -543px}
.flacon_24 {width:$w; height:$w; background-position: -5421px -543px}
.flacon_25 {width:$w; height:$w; background-position: -5963px -543px}
.flacon_26 {width:$w; height:$w; background-position: -6505px -543px}
.flacon_27 {width:$w; height:$w; background-position: -7047px -543px}
.flacon_28 {width:$w; height:$w; background-position: -1px -1085px}
.flacon_29 {width:$w; height:$w; background-position: -543px -1085px}
.flacon_30 {width:$w; height:$w; background-position: -1085px -1085px}
.flacon_31 {width:$w; height:$w; background-position: -1627px -1085px}
.flacon_32 {width:$w; height:$w; background-position: -2169px -1085px}
.flacon_33 {width:$w; height:$w; background-position: -2711px -1085px}
.flacon_34 {width:$w; height:$w; background-position: -3253px -1085px}
.flacon_35 {width:$w; height:$w; background-position: -3795px -1085px}
.flacon_36 {width:$w; height:$w; background-position: -4337px -1085px}
.flacon_37 {width:$w; height:$w; background-position: -4879px -1085px}
.flacon_38 {width:$w; height:$w; background-position: -5421px -1085px}
.flacon_39 {width:$w; height:$w; background-position: -5963px -1085px}
.flacon_40 {width:$w; height:$w; background-position: -6505px -1085px}
.flacon_41 {width:$w; height:$w; background-position: -7047px -1085px}
.flacon_42 {width:$w; height:$w; background-position: -1px -1627px}
.flacon_43 {width:$w; height:$w; background-position: -543px -1627px}
.flacon_44 {width:$w; height:$w; background-position: -1085px -1627px}
.flacon_45 {width:$w; height:$w; background-position: -1627px -1627px}
.flacon_46 {width:$w; height:$w; background-position: -2169px -1627px}
.flacon_47 {width:$w; height:$w; background-position: -2711px -1627px}
.flacon_48 {width:$w; height:$w; background-position: -3253px -1627px}
.flacon_49 {width:$w; height:$w; background-position: -3795px -1627px}
.flacon_50 {width:$w; height:$w; background-position: -4337px -1627px}
.flacon_51 {width:$w; height:$w; background-position: -4879px -1627px}
.flacon_52 {width:$w; height:$w; background-position: -5421px -1627px}
.flacon_53 {width:$w; height:$w; background-position: -5963px -1627px}
.flacon_54 {width:$w; height:$w; background-position: -6505px -1627px}
.flacon_55 {width:$w; height:$w; background-position: -7047px -1627px}
.flacon_56 {width:$w; height:$w; background-position: -1px -2169px}
.flacon_57 {width:$w; height:$w; background-position: -543px -2169px}
.flacon_58 {width:$w; height:$w; background-position: -1085px -2169px}
.flacon_59 {width:$w; height:$w; background-position: -1627px -2169px}
.flacon_60 {width:$w; height:$w; background-position: -2169px -2169px}
.flacon_61 {width:$w; height:$w; background-position: -2711px -2169px}
.flacon_62 {width:$w; height:$w; background-position: -3253px -2169px}
.flacon_63 {width:$w; height:$w; background-position: -3795px -2169px}
.flacon_64 {width:$w; height:$w; background-position: -4337px -2169px}
.flacon_65 {width:$w; height:$w; background-position: -4879px -2169px}
.flacon_66 {width:$w; height:$w; background-position: -5421px -2169px}
.flacon_67 {width:$w; height:$w; background-position: -5963px -2169px}
.flacon_68 {width:$w; height:$w; background-position: -6505px -2169px}
.flacon_69 {width:$w; height:$w; background-position: -7047px -2169px}
.flacon_70 {width:$w; height:$w; background-position: -1px -2711px}
.flacon_71 {width:$w; height:$w; background-position: -543px -2711px}
.flacon_72 {width:$w; height:$w; background-position: -1085px -2711px}
.flacon_73 {width:$w; height:$w; background-position: -1627px -2711px}
.flacon_74 {width:$w; height:$w; background-position: -2169px -2711px}
.flacon_75 {width:$w; height:$w; background-position: -2711px -2711px}
.flacon_76 {width:$w; height:$w; background-position: -3253px -2711px}
.flacon_77 {width:$w; height:$w; background-position: -3795px -2711px}
.flacon_78 {width:$w; height:$w; background-position: -4337px -2711px}
.flacon_79 {width:$w; height:$w; background-position: -4879px -2711px}
.flacon_80 {width:$w; height:$w; background-position: -5421px -2711px}
.flacon_81 {width:$w; height:$w; background-position: -5963px -2711px}
.flacon_82 {width:$w; height:$w; background-position: -6505px -2711px}
.flacon_83 {width:$w; height:$w; background-position: -7047px -2711px}
.flacon_84 {width:$w; height:$w; background-position: -1px -3253px}
.flacon_85 {width:$w; height:$w; background-position: -543px -3253px}
.flacon_86 {width:$w; height:$w; background-position: -1085px -3253px}
.flacon_87 {width:$w; height:$w; background-position: -1627px -3253px}
.flacon_88 {width:$w; height:$w; background-position: -2169px -3253px}
.flacon_89 {width:$w; height:$w; background-position: -2711px -3253px}
.flacon_90 {width:$w; height:$w; background-position: -3253px -3253px}
.flacon_91 {width:$w; height:$w; background-position: -3795px -3253px}
.flacon_92 {width:$w; height:$w; background-position: -4337px -3253px}
.flacon_93 {width:$w; height:$w; background-position: -4879px -3253px}
.flacon_94 {width:$w; height:$w; background-position: -5421px -3253px}
.flacon_95 {width:$w; height:$w; background-position: -5963px -3253px}
.flacon_96 {width:$w; height:$w; background-position: -6505px -3253px}
.flacon_97 {width:$w; height:$w; background-position: -7047px -3253px}
.flacon_98 {width:$w; height:$w; background-position: -1px -3795px}
.flacon_99 {width:$w; height:$w; background-position: -543px -3795px}
.flacon_100 {width:$w; height:$w; background-position: -1085px -3795px}
.flacon_101 {width:$w; height:$w; background-position: -1627px -3795px}
.flacon_102 {width:$w; height:$w; background-position: -2169px -3795px}
.flacon_103 {width:$w; height:$w; background-position: -2711px -3795px}
.flacon_104 {width:$w; height:$w; background-position: -3253px -3795px}
.flacon_105 {width:$w; height:$w; background-position: -3795px -3795px}
.flacon_106 {width:$w; height:$w; background-position: -4337px -3795px}
.flacon_107 {width:$w; height:$w; background-position: -4879px -3795px}
.flacon_108 {width:$w; height:$w; background-position: -5421px -3795px}
.flacon_109 {width:$w; height:$w; background-position: -5963px -3795px}
.flacon_110 {width:$w; height:$w; background-position: -6505px -3795px}
.flacon_111 {width:$w; height:$w; background-position: -7047px -3795px}
.flacon_112 {width:$w; height:$w; background-position: -1px -4337px}
.flacon_113 {width:$w; height:$w; background-position: -543px -4337px}
.flacon_114 {width:$w; height:$w; background-position: -1085px -4337px}
.flacon_115 {width:$w; height:$w; background-position: -1627px -4337px}
.flacon_116 {width:$w; height:$w; background-position: -2169px -4337px}
.flacon_117 {width:$w; height:$w; background-position: -2711px -4337px}
.flacon_118 {width:$w; height:$w; background-position: -3253px -4337px}
.flacon_119 {width:$w; height:$w; background-position: -3795px -4337px}
.flacon_120 {width:$w; height:$w; background-position: -4337px -4337px}
.flacon_121 {width:$w; height:$w; background-position: -4879px -4337px}
.flacon_122 {width:$w; height:$w; background-position: -5421px -4337px}
.flacon_123 {width:$w; height:$w; background-position: -5963px -4337px}
.flacon_124 {width:$w; height:$w; background-position: -6505px -4337px}
.flacon_125 {width:$w; height:$w; background-position: -7047px -4337px}
.flacon_126 {width:$w; height:$w; background-position: -1px -4879px}
.flacon_127 {width:$w; height:$w; background-position: -543px -4879px}
.flacon_128 {width:$w; height:$w; background-position: -1085px -4879px}
.flacon_129 {width:$w; height:$w; background-position: -1627px -4879px}
.flacon_130 {width:$w; height:$w; background-position: -2169px -4879px}
.flacon_131 {width:$w; height:$w; background-position: -2711px -4879px}
.flacon_132 {width:$w; height:$w; background-position: -3253px -4879px}
.flacon_133 {width:$w; height:$w; background-position: -3795px -4879px}
.flacon_134 {width:$w; height:$w; background-position: -4337px -4879px}
.flacon_135 {width:$w; height:$w; background-position: -4879px -4879px}
.flacon_136 {width:$w; height:$w; background-position: -5421px -4879px}
.flacon_137 {width:$w; height:$w; background-position: -5963px -4879px}
.flacon_138 {width:$w; height:$w; background-position: -6505px -4879px}
.flacon_139 {width:$w; height:$w; background-position: -7047px -4879px}
.flacon_140 {width:$w; height:$w; background-position: -1px -5421px}
.flacon_141 {width:$w; height:$w; background-position: -543px -5421px}
.flacon_142 {width:$w; height:$w; background-position: -1085px -5421px}
.flacon_143 {width:$w; height:$w; background-position: -1627px -5421px}
.flacon_144 {width:$w; height:$w; background-position: -2169px -5421px}
.flacon_145 {width:$w; height:$w; background-position: -2711px -5421px}
.flacon_146 {width:$w; height:$w; background-position: -3253px -5421px}
.flacon_147 {width:$w; height:$w; background-position: -3795px -5421px}
.flacon_148 {width:$w; height:$w; background-position: -4337px -5421px}
.flacon_149 {width:$w; height:$w; background-position: -4879px -5421px}
.flacon_150 {width:$w; height:$w; background-position: -5421px -5421px}
.flacon_151 {width:$w; height:$w; background-position: -5963px -5421px}
.flacon_152 {width:$w; height:$w; background-position: -6505px -5421px}
.flacon_153 {width:$w; height:$w; background-position: -7047px -5421px}
.flacon_154 {width:$w; height:$w; background-position: -1px -5963px}
.flacon_155 {width:$w; height:$w; background-position: -543px -5963px}
.flacon_156 {width:$w; height:$w; background-position: -1085px -5963px}
.flacon_157 {width:$w; height:$w; background-position: -1627px -5963px}
.flacon_158 {width:$w; height:$w; background-position: -2169px -5963px}
.flacon_159 {width:$w; height:$w; background-position: -2711px -5963px}
.flacon_160 {width:$w; height:$w; background-position: -3253px -5963px}
.flacon_161 {width:$w; height:$w; background-position: -3795px -5963px}
.flacon_162 {width:$w; height:$w; background-position: -4337px -5963px}
.flacon_163 {width:$w; height:$w; background-position: -4879px -5963px}
.flacon_164 {width:$w; height:$w; background-position: -5421px -5963px}
.flacon_165 {width:$w; height:$w; background-position: -5963px -5963px}
.flacon_166 {width:$w; height:$w; background-position: -6505px -5963px}
.flacon_167 {width:$w; height:$w; background-position: -7047px -5963px}
.flacon_168 {width:$w; height:$w; background-position: -1px -6505px}
.flacon_169 {width:$w; height:$w; background-position: -543px -6505px}
.flacon_170 {width:$w; height:$w; background-position: -1085px -6505px}
.flacon_171 {width:$w; height:$w; background-position: -1627px -6505px}
.flacon_172 {width:$w; height:$w; background-position: -2169px -6505px}
.flacon_173 {width:$w; height:$w; background-position: -2711px -6505px}
.flacon_174 {width:$w; height:$w; background-position: -3253px -6505px}
.flacon_175 {width:$w; height:$w; background-position: -3795px -6505px}
.flacon_176 {width:$w; height:$w; background-position: -4337px -6505px}
.flacon_177 {width:$w; height:$w; background-position: -4879px -6505px}
.flacon_178 {width:$w; height:$w; background-position: -5421px -6505px}
.flacon_179 {width:$w; height:$w; background-position: -5963px -6505px}
.flacon_180 {width:$w; height:$w; background-position: -6505px -6505px}

@media screen and (max-height: 800px) and (min-width: 768px) {
    .sprite-container {
        transform: scale(0.55) translateY(-50px);
    }
}

@include max-width(s) {

    .sprite-container {
        transform: scale(0.8);
        margin-top: 20px;
        width: 319px;
        height: 319px;
    }
    
    .sprite {
        width: 319px;
        height: 319px;
        background-image:url('../../assets/scandal-flacon-sprite-mobile.png');
    }

    .flacon_0 {width:319px; height:319px; background-position: -1px -1px}
    .flacon_1 {width:319px; height:319px; background-position: -1px -322px}
    .flacon_2 {width:319px; height:319px; background-position: -1px -643px}
    .flacon_3 {width:319px; height:319px; background-position: -1px -964px}
    .flacon_4 {width:319px; height:319px; background-position: -1px -1285px}
    .flacon_5 {width:319px; height:319px; background-position: -1px -1606px}
    .flacon_6 {width:319px; height:319px; background-position: -1px -1927px}
    .flacon_7 {width:319px; height:319px; background-position: -1px -2248px}
    .flacon_8 {width:319px; height:319px; background-position: -322px -1px}
    .flacon_9 {width:319px; height:319px; background-position: -322px -322px}
    .flacon_10 {width:319px; height:319px; background-position: -322px -643px}
    .flacon_11 {width:319px; height:319px; background-position: -322px -964px}
    .flacon_12 {width:319px; height:319px; background-position: -322px -1285px}
    .flacon_13 {width:319px; height:319px; background-position: -322px -1606px}
    .flacon_14 {width:319px; height:319px; background-position: -322px -1927px}
    .flacon_15 {width:319px; height:319px; background-position: -322px -2248px}
    .flacon_16 {width:319px; height:319px; background-position: -643px -1px}
    .flacon_17 {width:319px; height:319px; background-position: -643px -322px}
    .flacon_18 {width:319px; height:319px; background-position: -643px -643px}
    .flacon_19 {width:319px; height:319px; background-position: -643px -964px}
    .flacon_20 {width:319px; height:319px; background-position: -643px -1285px}
    .flacon_21 {width:319px; height:319px; background-position: -643px -1606px}
    .flacon_22 {width:319px; height:319px; background-position: -643px -1927px}
    .flacon_23 {width:319px; height:319px; background-position: -643px -2248px}
    .flacon_24 {width:319px; height:319px; background-position: -964px -1px}
    .flacon_25 {width:319px; height:319px; background-position: -964px -322px}
    .flacon_26 {width:319px; height:319px; background-position: -964px -643px}
    .flacon_27 {width:319px; height:319px; background-position: -964px -964px}
    .flacon_28 {width:319px; height:319px; background-position: -964px -1285px}
    .flacon_29 {width:319px; height:319px; background-position: -964px -1606px}
    .flacon_30 {width:319px; height:319px; background-position: -964px -1927px}
    .flacon_31 {width:319px; height:319px; background-position: -964px -2248px}
    .flacon_32 {width:319px; height:319px; background-position: -1285px -1px}
    .flacon_33 {width:319px; height:319px; background-position: -1285px -322px}
    .flacon_34 {width:319px; height:319px; background-position: -1285px -643px}
    .flacon_35 {width:319px; height:319px; background-position: -1285px -964px}
    .flacon_36 {width:319px; height:319px; background-position: -1285px -1285px}
    .flacon_37 {width:319px; height:319px; background-position: -1285px -1606px}
    .flacon_38 {width:319px; height:319px; background-position: -1285px -1927px}
    .flacon_39 {width:319px; height:319px; background-position: -1285px -2248px}
    .flacon_40 {width:319px; height:319px; background-position: -1606px -1px}
    .flacon_41 {width:319px; height:319px; background-position: -1606px -322px}
    .flacon_42 {width:319px; height:319px; background-position: -1606px -643px}
    .flacon_43 {width:319px; height:319px; background-position: -1606px -964px}
    .flacon_44 {width:319px; height:319px; background-position: -1606px -1285px}
    .flacon_45 {width:319px; height:319px; background-position: -1606px -1606px}
    .flacon_46 {width:319px; height:319px; background-position: -1606px -1927px}
    .flacon_47 {width:319px; height:319px; background-position: -1606px -2248px}
    .flacon_48 {width:319px; height:319px; background-position: -1927px -1px}
    .flacon_49 {width:319px; height:319px; background-position: -1927px -322px}
    .flacon_50 {width:319px; height:319px; background-position: -1927px -643px}
    .flacon_51 {width:319px; height:319px; background-position: -1927px -964px}
    .flacon_52 {width:319px; height:319px; background-position: -1927px -1285px}
    .flacon_53 {width:319px; height:319px; background-position: -1927px -1606px}
    .flacon_54 {width:319px; height:319px; background-position: -1927px -1927px}
    .flacon_55 {width:319px; height:319px; background-position: -1927px -2248px}
    .flacon_56 {width:319px; height:319px; background-position: -2248px -1px}
    .flacon_57 {width:319px; height:319px; background-position: -2248px -322px}
    .flacon_58 {width:319px; height:319px; background-position: -2248px -643px}
    .flacon_59 {width:319px; height:319px; background-position: -2248px -964px}
    .flacon_60 {width:319px; height:319px; background-position: -2248px -1285px}
    .flacon_61 {width:319px; height:319px; background-position: -2248px -1606px}
    .flacon_62 {width:319px; height:319px; background-position: -2248px -1927px}
    .flacon_63 {width:319px; height:319px; background-position: -2248px -2248px}
    .flacon_64 {width:319px; height:319px; background-position: -2569px -1px}
    .flacon_65 {width:319px; height:319px; background-position: -2569px -322px}
    .flacon_66 {width:319px; height:319px; background-position: -2569px -643px}
    .flacon_67 {width:319px; height:319px; background-position: -2569px -964px}
    .flacon_68 {width:319px; height:319px; background-position: -2569px -1285px}
    .flacon_69 {width:319px; height:319px; background-position: -2569px -1606px}
    .flacon_70 {width:319px; height:319px; background-position: -2569px -1927px}
    .flacon_71 {width:319px; height:319px; background-position: -2569px -2248px}
    .flacon_72 {width:319px; height:319px; background-position: -2890px -1px}
    .flacon_73 {width:319px; height:319px; background-position: -2890px -322px}
    .flacon_74 {width:319px; height:319px; background-position: -2890px -643px}
    .flacon_75 {width:319px; height:319px; background-position: -2890px -964px}
    .flacon_76 {width:319px; height:319px; background-position: -2890px -1285px}
    .flacon_77 {width:319px; height:319px; background-position: -2890px -1606px}
    .flacon_78 {width:319px; height:319px; background-position: -2890px -1927px}
    .flacon_79 {width:319px; height:319px; background-position: -2890px -2248px}
    .flacon_80 {width:319px; height:319px; background-position: -3211px -1px}
    .flacon_81 {width:319px; height:319px; background-position: -3211px -322px}
    .flacon_82 {width:319px; height:319px; background-position: -3211px -643px}
    .flacon_83 {width:319px; height:319px; background-position: -3211px -964px}
    .flacon_84 {width:319px; height:319px; background-position: -3211px -1285px}
    .flacon_85 {width:319px; height:319px; background-position: -3211px -1606px}
    .flacon_86 {width:319px; height:319px; background-position: -3211px -1927px}
    .flacon_87 {width:319px; height:319px; background-position: -3211px -2248px}
    .flacon_88 {width:319px; height:319px; background-position: -3532px -1px}
    .flacon_89 {width:319px; height:319px; background-position: -3532px -322px}
    .flacon_90 {width:319px; height:319px; background-position: -3532px -643px}
    .flacon_91 {width:319px; height:319px; background-position: -3532px -964px}
    .flacon_92 {width:319px; height:319px; background-position: -3532px -1285px}
    .flacon_93 {width:319px; height:319px; background-position: -3532px -1606px}
    .flacon_94 {width:319px; height:319px; background-position: -3532px -1927px}
    .flacon_95 {width:319px; height:319px; background-position: -3532px -2248px}
    .flacon_96 {width:319px; height:319px; background-position: -3853px -1px}
    .flacon_97 {width:319px; height:319px; background-position: -3853px -322px}
    .flacon_98 {width:319px; height:319px; background-position: -3853px -643px}
    .flacon_99 {width:319px; height:319px; background-position: -3853px -964px}
    .flacon_100 {width:319px; height:319px; background-position: -3853px -1285px}
    .flacon_101 {width:319px; height:319px; background-position: -3853px -1606px}
    .flacon_102 {width:319px; height:319px; background-position: -3853px -1927px}
    .flacon_103 {width:319px; height:319px; background-position: -3853px -2248px}
    .flacon_104 {width:319px; height:319px; background-position: -4174px -1px}
    .flacon_105 {width:319px; height:319px; background-position: -4174px -322px}
    .flacon_106 {width:319px; height:319px; background-position: -4174px -643px}
    .flacon_107 {width:319px; height:319px; background-position: -4174px -964px}
    .flacon_108 {width:319px; height:319px; background-position: -4174px -1285px}
    .flacon_109 {width:319px; height:319px; background-position: -4174px -1606px}
    .flacon_110 {width:319px; height:319px; background-position: -4174px -1927px}
    .flacon_111 {width:319px; height:319px; background-position: -4174px -2248px}
    .flacon_112 {width:319px; height:319px; background-position: -4495px -1px}
    .flacon_113 {width:319px; height:319px; background-position: -4495px -322px}
    .flacon_114 {width:319px; height:319px; background-position: -4495px -643px}
    .flacon_115 {width:319px; height:319px; background-position: -4495px -964px}
    .flacon_116 {width:319px; height:319px; background-position: -4495px -1285px}
    .flacon_117 {width:319px; height:319px; background-position: -4495px -1606px}
    .flacon_118 {width:319px; height:319px; background-position: -4495px -1927px}
    .flacon_119 {width:319px; height:319px; background-position: -4495px -2248px}
    .flacon_120 {width:319px; height:319px; background-position: -4816px -1px}
    .flacon_121 {width:319px; height:319px; background-position: -4816px -322px}
    .flacon_122 {width:319px; height:319px; background-position: -4816px -643px}
    .flacon_123 {width:319px; height:319px; background-position: -4816px -964px}
    .flacon_124 {width:319px; height:319px; background-position: -4816px -1285px}
    .flacon_125 {width:319px; height:319px; background-position: -4816px -1606px}
    .flacon_126 {width:319px; height:319px; background-position: -4816px -1927px}
    .flacon_127 {width:319px; height:319px; background-position: -4816px -2248px}
    .flacon_128 {width:319px; height:319px; background-position: -5137px -1px}
    .flacon_129 {width:319px; height:319px; background-position: -5458px -1px}
    .flacon_130 {width:319px; height:319px; background-position: -5779px -1px}
    .flacon_131 {width:319px; height:319px; background-position: -6100px -1px}
    .flacon_132 {width:319px; height:319px; background-position: -6421px -1px}
    .flacon_133 {width:319px; height:319px; background-position: -6742px -1px}
    .flacon_134 {width:319px; height:319px; background-position: -7063px -1px}
    .flacon_135 {width:319px; height:319px; background-position: -5137px -322px}
    .flacon_136 {width:319px; height:319px; background-position: -5137px -643px}
    .flacon_137 {width:319px; height:319px; background-position: -5137px -964px}
    .flacon_138 {width:319px; height:319px; background-position: -5137px -1285px}
    .flacon_139 {width:319px; height:319px; background-position: -5137px -1606px}
    .flacon_140 {width:319px; height:319px; background-position: -5137px -1927px}
    .flacon_141 {width:319px; height:319px; background-position: -5137px -2248px}
    .flacon_142 {width:319px; height:319px; background-position: -5458px -322px}
    .flacon_143 {width:319px; height:319px; background-position: -5779px -322px}
    .flacon_144 {width:319px; height:319px; background-position: -6100px -322px}
    .flacon_145 {width:319px; height:319px; background-position: -6421px -322px}
    .flacon_146 {width:319px; height:319px; background-position: -6742px -322px}
    .flacon_147 {width:319px; height:319px; background-position: -7063px -322px}
    .flacon_148 {width:319px; height:319px; background-position: -5458px -643px}
    .flacon_149 {width:319px; height:319px; background-position: -5458px -964px}
    .flacon_150 {width:319px; height:319px; background-position: -5458px -1285px}
    .flacon_151 {width:319px; height:319px; background-position: -5458px -1606px}
    .flacon_152 {width:319px; height:319px; background-position: -5458px -1927px}
    .flacon_153 {width:319px; height:319px; background-position: -5458px -2248px}
    .flacon_154 {width:319px; height:319px; background-position: -5779px -643px}
    .flacon_155 {width:319px; height:319px; background-position: -6100px -643px}
    .flacon_156 {width:319px; height:319px; background-position: -6421px -643px}
    .flacon_157 {width:319px; height:319px; background-position: -6742px -643px}
    .flacon_158 {width:319px; height:319px; background-position: -7063px -643px}
    .flacon_159 {width:319px; height:319px; background-position: -5779px -964px}
    .flacon_160 {width:319px; height:319px; background-position: -5779px -1285px}
    .flacon_161 {width:319px; height:319px; background-position: -5779px -1606px}
    .flacon_162 {width:319px; height:319px; background-position: -5779px -1927px}
    .flacon_163 {width:319px; height:319px; background-position: -5779px -2248px}
    .flacon_164 {width:319px; height:319px; background-position: -6100px -964px}
    .flacon_165 {width:319px; height:319px; background-position: -6421px -964px}
    .flacon_166 {width:319px; height:319px; background-position: -6742px -964px}
    .flacon_167 {width:319px; height:319px; background-position: -7063px -964px}
    .flacon_168 {width:319px; height:319px; background-position: -6100px -1285px}
    .flacon_169 {width:319px; height:319px; background-position: -6100px -1606px}
    .flacon_170 {width:319px; height:319px; background-position: -6100px -1927px}
    .flacon_171 {width:319px; height:319px; background-position: -6100px -2248px}
    .flacon_172 {width:319px; height:319px; background-position: -6421px -1285px}
    .flacon_173 {width:319px; height:319px; background-position: -6742px -1285px}
    .flacon_174 {width:319px; height:319px; background-position: -7063px -1285px}
    .flacon_175 {width:319px; height:319px; background-position: -6421px -1606px}
    .flacon_176 {width:319px; height:319px; background-position: -6421px -1927px}
    .flacon_177 {width:319px; height:319px; background-position: -6421px -2248px}
    .flacon_178 {width:319px; height:319px; background-position: -6742px -1606px}
    .flacon_179 {width:319px; height:319px; background-position: -7063px -1606px}
    .flacon_180 {width:319px; height:319px; background-position: -6742px -1927px}

}

@include max-width(xxs) {

    .sprite-container {
        transform: scale(0.65);
        margin-top: 0;
    }
}

</style>
