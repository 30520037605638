<template>
    <div class="video-container">
        <video loop="loop" autoplay="autoplay" muted="muted" playsinline src="../assets/videos/video-splash-screen-no-audio-1080.mp4"></video>
    </div>
</template>

<script>
export default {
    name: "VideoSplashScreen"
};
</script>

<style lang="scss">

.video-container {
    position: absolute;
    z-index: 888;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    overflow: hidden;
    background: linear-gradient(180deg, #192935 0%, #121C25 100%);

    video {
        position: absolute;
        top: 50%;
        left: 50%;
        display: block;
        width: 100%;
        height: auto;
        transform: translate(-50%, -50%);
    }
}

@media (max-aspect-ratio: 9/16) {
    .video-container video {
        width: auto;
        height: 100%;
    }
}

</style>
