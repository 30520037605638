<template>
    <div v-on:click="$emit('click')" class="button--start noselect">
        <span>
            {{ text }}
        </span>
        <div class="progress-decorator" :style="progressStyle"></div>
    </div>
</template>

<script>
export default {
    name: "Button",
    data() {
        return {
            text: this.loadingLabel
        }
    },
    props: {
        loadingLabel: String,
        label: String,
        progress: Number
    },
    computed: {
        progressStyle() {
            return {transform: 'translateY('+ - this.$props.progress +'%)'}
        }
    },
    updated() {
        if (this.$props.progress === 100) {
            window.setTimeout( () => {
                this.text = this.$props.label
            }, 800)
        }
    },
    mounted() {
        if (this.$props.progress === 100) {
            this.text = this.$props.label
        }
    },
};
</script>

<style lang="scss">
.button--start {
    position: relative;
    display: inline-block;
    min-width: 160px;
    border: 1px solid $color-sorrel-brown;

    text-transform: uppercase;
    font-size: 17px;
    letter-spacing: -0.02em;
    color: $color-white;
    line-height: 50px;

    overflow: hidden;
    cursor: pointer;

    transition: color 150ms $magikMotion;

    span {
        position: relative;
        z-index: 1;
    }

    .progress-decorator {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        width: 100%;
        height: 100%;
        z-index: 0;
        transition: all 300ms $magikMotion 500ms;

        &::after {
            content: '';
            position: absolute;
            display: block;
            bottom: -100%;
            left: 0;
            right: 0;
            width: 100%;
            height: 100%;
            background-color: $color-sorrel-brown;
        }
    }
    

    &:hover {
        color: $color-black;

        .decorator {
            transform: translateY(0);
        }
    }
}
</style>
