/* eslint-disable */
import {Howl, Howler} from 'howler';

const spriteTiming={
    background: [11808, 29883,true],
    decompte: [0, 5183],
    transition: [9936, 1871],
    excellent: [6768, 1531],
    greater: [5184, 1583],
    combot: [8584, 1352],
}

export default class audioController{
    constructor(){
        this.sound = null;
        this.setAudio();
        this.mute = false;
        this.loop = false;
    }

    setAudio(){
        this.sound = new Howl({
            src: ['/game/sound/jpg_audioSprite.ogg','/game/sound/jpg_audioSprite.mp3'],
            sprite: spriteTiming
        });     
    }
    playAudio(_audio){
        if(!this.mute){
            if(_audio == 'background'){
                if(!this.loop){
                    this.loop = true;
                    this.sound.play(_audio);
                }
            }else{
                this.sound.play(_audio);
            }
           
        }
    }
    muteAll(){
        this.sound.stop()
        this.mute = true;
    }
    unmuteAll(){
        this.sound.play('background');
        this.mute = false;
    }
}
/* eslint-enable */