import Vue from 'vue'
import App from './App.vue'
import router from './router'
import i18n from './i18n'
import './utils/gtm.js'

import audioController from './utils/AudioController.js'
const audio = new audioController;
Object.defineProperty(Vue.prototype, '$audio', { value: audio });
 
Vue.config.productionTip = false


new Vue({
  router,
  i18n,
  render: h => h(App)
}).$mount('#app')

