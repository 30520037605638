import Vue from 'vue'
import VueRouter from 'vue-router'
import i18n from '@/i18n'
import Home from '@/views/Home.vue'
import PageNotFound from '@/views/PageNotFound.vue'
import NocibePOS from '@/views/NocibePOS.vue'
import SuissePOS from '@/views/SuissePOS.vue'
import MexicoPOS from '@/views/MexicoPOS.vue'
import MexicoTR from '@/views/MexicoTR.vue'
import Argentina from '@/views/Argentina.vue'
import { getSupportedLocales } from "@/utils/supported-locales.js"
import SephoraPOS from "../views/SephoraPOS";

Vue.use(VueRouter)

const supportedLocalesCode = []

getSupportedLocales().forEach( (locale) => {
    supportedLocalesCode.push(locale.code)
})

const routes = [
    {
        path: '/',
        redirect: `/${i18n.locale}`
    },

    {
        path: '/:locale',
        component: {
            render(c) { return c('router-view') }
        },
        beforeEnter: (to, from, next) => {
            let locale = to.params.locale
            if (supportedLocalesCode.includes(locale)) {
                return next()
            }
            return next({path: to.path.replace(locale, 'en')})
        },
        children: [
            {
                path: "/",
                //redirect: '/fr/retailer/sephora', //Change to good route
                name: "home",
                meta: {
                    sample: false,
                    discoverURL: 'https://www.jeanpaulgaultier.com/scandal-pour-homme/thefragrance',
                    subscribeURL: 'https://www.jeanpaulgaultier.com/fragrances#join-newsletter-form--2',
                    langControl: false,
                    taggingPlan: true,
                    audioControl: true
                },
                component: Home
            },
            {
                path: "uk",
                name: "uk",
                meta: {
                    sample: false,
                    discoverURL: 'https://www.jeanpaulgaultier.com/uk/en/fragrances/range-scandal-pour-homme/scandal-pour-homme-eau-de-toilette',
                    subscribeURL: 'https://www.jeanpaulgaultier.com/uk/en/fragrances#join-newsletter-form--2',
                    langControl: false,
                    taggingPlan: true,
                    audioControl: true
                },
                component: Home
            },
            {
                path: "eu-es",
                name: "eu-es",
                meta: {
                    sample: false,
                    discoverURL: 'https://www.jeanpaulgaultier.com/eu/es/fragancias/linea-scandal-pour-homme/scandal-pour-homme-eau-de-toilette',
                    subscribeURL: 'https://www.jeanpaulgaultier.com/eu/es/fragancias#join-newsletter-form--2',
                    langControl: false,
                    taggingPlan: true,
                    audioControl: true
                },
                component: Home
            },
            {
                path: "us",
                name: "us",
                meta: {
                    sample: false,
                    discoverURL: 'https://www.jeanpaulgaultier.com/us/en/fragrances/range-scandal-pour-homme/scandal-pour-homme-eau-de-toilette',
                    subscribeURL: 'https://www.jeanpaulgaultier.com/us/en/fragrances#join-newsletter-form--2',
                    langControl: false,
                    taggingPlan: true,
                    audioControl: true
                },
                component: Home
            },
            {
                path: "retailer/test-sample-embed",
                name: "testSampleEmbed",
                meta: {
                    sample: true,
                    formURL: 'https://campaign.jeanpaulgaultier.com/scandal-pour-homme-digirtt-france-fr',
                    discoverURL: 'https://google.com',
                    subscribeURL: 'https://www.jeanpaulgaultier.com/',
                    langControl: false,
                    taggingPlan: false,
                    audioControl: true
                },
                component: Home
            },
            {
                path: "retailer/lang-control",
                name: "langControl",
                meta: {
                    sample: true,
                    formURL: 'https://campaign.jeanpaulgaultier.com/scandal-pour-homme-digirtt-france-fr',
                    discoverURL: 'https://google.com',
                    subscribeURL: 'https://www.jeanpaulgaultier.com/',
                    langControl: true,
                    taggingPlan: false,
                    audioControl: true
                },
                component: Home
            },
            {
                path: "retailer/test-sample-custom",
                name: "testSampleCustom",
                meta: {
                    sample: false,
                    discoverURL: 'https://google.com',
                    subscribeURL: 'https://www.jeanpaulgaultier.com/',
                    langControl: false,
                    taggingPlan: false,
                    audioControl: true
                },
                component: Home
            },
            {
                path: "retailer/nocibe",
                name: "nocibe",
                meta: {
                    sample: true,
                    formURL: 'https://campaign.jeanpaulgaultier.com/scandal-pour-homme-game-digirtt-fr-nocibe-fr',
                    discoverURL: 'https://google.com',
                    subscribeURL: 'https://www.jeanpaulgaultier.com/',
                    langControl: false,
                    taggingPlan: false,
                    audioControl: false
                },
                component: NocibePOS
            },
            {
                path: "retailer/sephora",
                name: "sephora",
                meta: {
                    sample: true,
                    formURL: 'https://campaign.jeanpaulgaultier.com/scandal-mb-lp-jeu-tablette-sephora-fr-fr/fr',
                    discoverURL: 'https://google.com',
                    subscribeURL: 'https://www.jeanpaulgaultier.com/',
                    langControl: false,
                    langHUB: true,
                    taggingPlan: false,
                    audioControl: false
                },
                component: SephoraPOS
            },
            {
                path: "retailer/suisse",
                name: "suisse",
                meta: {
                    sample: false,
                    formURL: 'https://campaign.jeanpaulgaultier.com/scandal-pour-homme-game-digirtt-fr-nocibe-fr',
                    discoverURL: 'https://google.com',
                    subscribeURL: 'https://www.jeanpaulgaultier.com/',
                    langControl: false,
                    taggingPlan: false,
                    audioControl: false
                },
                component: SuissePOS
            },
            {
                path: "eretailer/impo",
                name: "impo",
                meta: {
                    sample: false,
                    formURL: '',
                    discoverURL: 'https://www.impo.ch/p/6809125',
                    subscribeURL: 'https://www.impo.ch/newsletteranmeldung',
                    langControl: false,
                    taggingPlan: true,
                    audioControl: true
                },
                component: Home
            },
            {
                path: "eretailer/thefragranceshop",
                name: "thefragranceshop",
                meta: {
                    sample: false,
                    formURL: '',
                    discoverURL: '',
                    subscribeURL: 'https://www.thefragranceshop.co.uk/jpg-sign-up',
                    subscribeCTAClass: 'full',
                    langControl: false,
                    taggingPlan: true,
                    audioControl: true
                },
                component: Home
            },
            {
                path: "retailer/mexico",
                name: "mexico",
                meta: {
                    sample: true,
                    formURL: 'https://campaign.jeanpaulgaultier.com/scandal-pour-homme-game-digirtt-fr-nocibe-fr',
                    discoverURL: 'https://google.com',
                    subscribeURL: 'https://www.jeanpaulgaultier.com/',
                    langControl: false,
                    taggingPlan: false,
                    audioControl: false
                },
                component: MexicoPOS
            },
            {
                path: "retailer/mexicotr",
                name: "mexicotr",
                meta: {
                    sample: true,
                    langControl: false,
                    langHUB: true,
                    taggingPlan: false,
                    audioControl: false
                },
                component: MexicoTR
            },
            {
                path: "eretailer/argentina",
                name: "argentina",
                meta: {
                    sample: false,
                    discoverURL: 'https://www.juleriaque.com.ar/scandal-pour-homme-edt/p',
                    langControl: false,
                    taggingPlan: true,
                    audioControl: true
                },
                component: Argentina
            }
        ]
    },
    {
        path: "*",
        component: PageNotFound
    }
]

const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    routes
})

router.beforeEach((to, from, next) => {
    let language = to.params.locale
    
    if (!language) {
        language = 'en'
    }

    i18n.locale = language
    next()
})

export default router
